import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Button, Card, Form } from 'react-bootstrap';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { listServices } from '../actions/serviceActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import image1 from '../assets/images/education-page/problem-solving.svg'
import Service from '../components/Service';

function ServiceScreen() {

  const dispatch = useDispatch()

  let navigate = useNavigate();
  let keyword = useLocation().search

  const serviceList = useSelector(state => state.serviceList)
  const { error, loading, services } = serviceList

  useEffect(() => {
    dispatch(listServices(keyword))

     window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
     
  }, [dispatch, keyword])
  
  return (

    <div>
    <Link to='/' className='btn btn-light mt-2'>Go Back</Link>
    <section >
      <div class="container-fluid px-3 pt-5">
        <div class="row text-dark">
          <div class="col-md-6 my-2"> <img class="img-fluid d-block  animate__animated animate__fadeInUp animate__delay-1s"
              src={image1} />
          </div>
          <div
            class="px-md-5 p-3 col-md-6 d-flex flex-column align-items-center justify-content-center animate__animated animate__fadeInUp animate__delay-1s">
            <h1 class="main-heading mt-3 text-info">Servicios</h1>
            <p class="h5  mt-2 pre-heading font-weight-bolder text-center animate__animated animate__fadeInUp animate__delay-2s">
                Hagas lo que hagas, hazlo bien. Hazlo tan bien que cuando la gente te vea hacerlo quiera volver y verte hacerlo de nuevo, 
                y querrán traer a otros y mostrarles lo bien que lo haces. <br / ><br /> <span className='text-info fs-6'>- Walt Disney.</span>              
            </p>
          </div>
        </div>
      </div>
    </section>

    <h2 class="text-center text-info exp-head heading1 my-2 animate__animated animate__zoomIn animate__delay-1s formal-edu">Servicios Profesionales</h2>
    <p className='text-info my-3 text-center fw-bolder'>Si requieres una cotización, <Link to='/contact'>contactáctame</Link>, de inmediato te constestaré.</p>
    {
      loading ? <Loader /> 
      : error ? <Message variant='danger'>{error}</Message>
        : <div>
        <Row className='mx-1'>
          {services.length === 0 ? <h4><b>No existen Experiencias actualmente</b></h4> : ''}
            {services.map(service => (
              <Col key={service._id} sm={12}>
                  <Service service={service} />
              </Col>                        
          ))}
        </Row>
        </div>
  }

  </div>


  )
}

export default ServiceScreen
