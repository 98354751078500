import React, { useEffect, useState } from 'react';
import { Row, Col, Image, Card } from 'react-bootstrap';
import Moment from 'react-moment';
import { Link } from 'react-router-dom'
import ScrollAnimation from 'react-animate-on-scroll';

function Mentor({ mentor }) {

  useEffect(() => {

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    
}, [])



  return (
    <div class="mt-2">
    <ScrollAnimation animateIn="fadeIn duration='2s' offset">
    <Card className='mb-3 border border-success border-3 rounded h-100 imageprofile animate__animated animate__fadeInUp animate__delay-1s card'>
        <div class='row'>
          <div class='col-md-5'>
              <Card.Img src={mentor.image} className="card-img-top my-2 img-fluid" alt="Imagen" />
          </div>
          <div class='col-md-7'>
            <Card.Header className='bg-info'>
              <h3 className='text-center text-light'>{mentor.name}</h3>
            </Card.Header>
                  <Card.Body>
                     <Card.Text className='text-center h4'><b>Lugar:</b> {mentor.place}</Card.Text>
                     <ul>
                        {mentor.activity1 ? <li>{mentor.activity1}</li> : ''}
                        {mentor.activity2 ? <li>{mentor.activity2}</li> : ''}
                        {mentor.activity3 ? <li>{mentor.activity3}</li> : ''}
                        {mentor.activity4 ? <li>{mentor.activity4}</li> : ''}                                                                        
                     </ul>
                     <Card.Text className='text-center h6'><strong>Período:</strong> {mentor.timeline}</Card.Text>                     
                  </Card.Body>
                <Card.Footer as='div'>
                <div class="d-flex flex-row bd-highlight justify-content-center align-items-center">
                  <p className='fs-5 text-info fw-bolder mt-2'><b>{mentor.comment}</b></p>
                </div>
                </Card.Footer>
            </div>
       </div>
    </Card>
    </ScrollAnimation>    
    </div>
  )
}

export default Mentor