import axios from 'axios'
import { 
    MOOC_LIST_REQUEST, 
    MOOC_LIST_SUCCESS, 
    MOOC_LIST_FAIL,
    MOOC_DETAILS_REQUEST,
    MOOC_DETAILS_SUCCESS,
    MOOC_DETAILS_FAIL,
    MOOC_DELETE_REQUEST, 
    MOOC_DELETE_SUCCESS, 
    MOOC_DELETE_FAIL,

    MOOC_CREATE_REQUEST, 
    MOOC_CREATE_SUCCESS, 
    MOOC_CREATE_FAIL,
    MOOC_CREATE_RESET,
    
    MOOC_UPDATE_REQUEST, 
    MOOC_UPDATE_SUCCESS, 
    MOOC_UPDATE_FAIL,
    MOOC_UPDATE_RESET,


} from '../constants/moocConstants'

export const listMoocs = (keyword = '') => async (dispatch) => {
    try{
        dispatch({type: MOOC_LIST_REQUEST })
        const { data } = await axios.get(`/api/moocs${keyword}`)

        dispatch({
            type: MOOC_LIST_SUCCESS,
            payload: data
        })

    }catch(error){
        dispatch({
            type: MOOC_LIST_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })        
    }
}

export const listMoocDetails = (id) => async (dispatch) => {
    try{
        dispatch({type: MOOC_DETAILS_REQUEST })
        const { data } = await axios.get(`/api/moocs/${id}`)

        dispatch({
            type: MOOC_DETAILS_SUCCESS,
            payload: data
        })

    }catch(error){
        dispatch({
            type: MOOC_DETAILS_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })        
    }
}

export const deleteMooc = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: MOOC_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/api/moocs/delete/${id}/`,
            config
        )

        dispatch({
            type: MOOC_DELETE_SUCCESS,
        })


    } catch (error) {
        dispatch({
            type: MOOC_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const createMooc = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: MOOC_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/moocs/create/`,
            {},
            config
        )

        dispatch({
            type: MOOC_CREATE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: MOOC_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const updateMooc = (mooc) => async (dispatch, getState) => {
    try {
        dispatch({
            type: MOOC_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/moocs/update/${mooc._id}/`,
            mooc,
            config
        )

        dispatch({
            type: MOOC_UPDATE_SUCCESS,
            payload: data
        })

        dispatch({ 
            type: MOOC_DETAILS_SUCCESS, 
            payload: data
        })


    } catch (error) {
        dispatch({
            type: MOOC_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}