import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Container } from 'react-bootstrap';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { createContact, updateContact } from '../actions/contactActions'
import FormContainer from '../components/FormContainer'
import { CONTACT_UPDATE_RESET } from '../constants/contactConstants'


function ContactScreen() {

    /*const { id } = useParams()
    const contactId = id*/

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')    
    const [topic, setTopic] = useState('')
    const [comment, setComment] = useState('')

    const [uploading, setUploading] = useState(false)    
                    
    const dispatch = useDispatch()
    const navigate = useNavigate()
    //const location = useLocation()

    const contactCreate = useSelector(state => state.contactCreate)
    const { loading: loadingCreate, success: successCreate, error: errorCreate, contact: createdContact } = contactCreate

    const contactUpdate = useSelector(state => state.contactUpdate)
    const { loading: loadingUpdate, success: successUpdate, error: errorUpdate } = contactUpdate 
  
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
  
    let keyword = useLocation().search

    useEffect(() => {

        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
   
    }, [])


    const createHandler = async (e) => {
        await axios.post(`/api/contacts/create/`, {
            'user': userInfo.user,
            'name': name,
            'email': email,
            'topic': topic,
            'comment': comment,
        },
        {
            headers: {
                "Authorization": `Bearer ${userInfo.token}`,
                "Content-Type": 'application/json'
            }
        }
    )
    .then(res => console.log(res), alert('Mensaje enviado a Eduardo...en breve se comunicara contigo'))
    .catch(error => console.err(error))
    }
 
  return (

    <div class="container-fluid py-2">
    <Link to='/' className='btn btn-light mt-2'>Go Back</Link>
        <div class="col-md-6 mx-auto text-center">
            <h1 class="h1 text-info"><strong>Contáctame</strong></h1>
            <h6>
               Necesitas alguna cotización? Tienes alguna duda o comentario de mis servicios, o quieres compartir tu experiencia?
            </h6>
            <p className='fs-5 text-success fw-bolder'>Tu mensaje es muy importante para mi. Gracias!</p>
        </div>


    <div class="container-fluid py-1">
        <div class="col text-end mt-2">
            <a href="whatsapp://send?text=Hola buen día, me puedes enviar información de..." data-action="share/whatsapp/share"
            target="_blank" className='text-decoration-none text-info'> <i class="fab fa-whatsapp mx-2 text-success" aria-hidden="true"></i><strong>Comunicate conmigo</strong></a>
        </div>

        {loadingCreate && <Loader />}
        {errorCreate && <Message variant='danger'>{errorCreate}</Message>} 

        <div class="row py-3">
            <Form className="col-md-9 m-auto" onSubmit={createHandler}>
                <div class="row">
                    <div className="col-md-6 mb-3">
                    <Form.Group controlId='name' className='my-2'>
                        <Form.Label><h5>Nombre</h5></Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='Ingresa el nombre'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>
                    </div>
                    <div className="col-md-6 mb-3">
                        <Form.Group controlId='email' className='my-2'>
                            <Form.Label><h5>Email</h5></Form.Label>
                            <Form.Control
                                type='text'
                                placeholder={'Ingresa el Correo Electrónico'}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>
                    </div>
                </div>
                <div class="mb-3">
                    <Form.Group controlId='topic' className='my-2'>
                        <Form.Label><h5>Asunto</h5></Form.Label>
                        <Form.Control
                            type='text'
                            placeholder={'Ingresa el Asunto'}
                            value={topic}
                            onChange={(e) => setTopic(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>
                </div>
                <div class="mb-3">
                    <Form.Group controlId='comment' className='my-2'>
                        <Form.Label><h5>Mensaje</h5></Form.Label>
                        <Form.Control
                            as='textarea'
                            rows = {5}
                            placeholder={'Ingresa el mensaje'}
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>
                </div>
                <div class="row">
                    <div class="col-md-12 text-end mt-2">
                        <button type="submit" class="btn btn-info btn-md">Enviar Información</button>
                    </div>
                </div>
            </Form>

        </div>

    </div>    
    
    
    </div>
  )
}

export default ContactScreen
