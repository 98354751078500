import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Button, Row, Col, Container} from 'react-bootstrap';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { listContacts, deleteContact } from '../actions/contactActions'

function ContactListScreen() {

  const dispatch = useDispatch()
  const navigate = useNavigate();
  
  const contactList = useSelector(state => state.contactList)
  const { loading, error, contacts } = contactList

  const contactDelete = useSelector(state => state.contactDelete)
  const { loading: loadingDelete, success: successDelete, error: errorDelete } = contactDelete

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  let keyword = useLocation().search

  useEffect(() => {
    if(userInfo && userInfo.isAdmin){
      dispatch(listContacts(keyword))
    }else{
      navigate('/login');
    }
    
    dispatch(listContacts(keyword))

  }, [dispatch, navigate, userInfo, keyword, successDelete])

  const deleteHandler = (id) => {
    if(window.confirm('Estas seguro de borrar este mensaje?')){
      dispatch(deleteContact(id))
    }
  }
  
  return (
    <Container>
    <div className='section1'>
        <Row className='align-items-center'>
            <Col>
                <h1 className='text-dark-50 my-4 text-info'>Mensajes de Clientes</h1>
            </Col>
           
        </Row>
        {loadingDelete && <Loader />}
        {errorDelete && <Message variant='danger'>{errorDelete}</Message>}

        {loading ? 
          (<Loader />)
        : error ?
          (<Message variant='danger'>{error}</Message>)
        : (
          <div>
            <Table striped bordered hover responsive className='table-sm'>
              <thead>
              <tr>
                <th>NOMBRE</th>
                <th>EMAIL</th>
                <th>ASUNTO</th>                
                <th>COMENTARIOS</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {contacts.map(contact => (
                <tr key={contact._id}>
                  <td>{contact.name}</td>
                  <td>{contact.email}</td>
                  <td>{contact.topic}</td>
                  <td>{contact.comment}</td>                  
                  <td>  
                    <Button variant='danger' className='btn-sm' onClick={() => deleteHandler(contact._id)}>
                      <i className='fas fa-trash'></i>
                    </Button>                    
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          </div>
        )  
      }
    </div>
    </Container>
  )
}

export default ContactListScreen
