import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom';
import { Row, Col, Image, ListGroup, Button, Card, Form } from 'react-bootstrap';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { useParams, useNavigate } from 'react-router-dom';
import { listForoDetails, createForoDetail } from '../actions/foroActions'
import { FORO_CREATE_DETAILS_RESET } from '../constants/foroConstants'
import Moment from 'react-moment';

function ForoDetailScreen({ match }) {
    const [rating, setRating] = useState(5)
    const [comment, setComment] = useState('')    

    const { id } = useParams();
    let navigate = useNavigate();
    const dispatch = useDispatch()

    const foroDetails = useSelector(state => state.foroDetails)
    const { error, loading, foro } = foroDetails

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
      
    let keyword = useLocation().search

    const foroDetailCreate = useSelector(state => state.foroDetailCreate)
    const { error: errorForoReview, loading: loadingForoReview, success: successForoReview } = foroDetailCreate

    useEffect(() => {
        if(successForoReview){
            setRating(5)
            setComment('')
            dispatch({type: FORO_CREATE_DETAILS_RESET})
        }

        dispatch(listForoDetails(id))

        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        
    }, [dispatch, id, successForoReview, keyword])

 
    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(createForoDetail(id, {
            rating,
            comment
        }))
    }

  return (
    <div className="section1 container-fluid">
        <Link className='btn btn-light my-2 section1' to='/foro'>Go Back</Link>
        {
            loading ? <Loader />
            : error ? <Message variant='danger'>{error}</Message>
            : (
            <div>
            <div class='row'>
                <div class='col-sm-12 d-flex justify-content-center align-items-center mb-1'>
                    <h1 className='my-1 text-info'><b>{foro.name}</b></h1> 
                </div>
            </div>
            <div class="d-flex flex-row bd-highlight justify-content-center align-items-center">
            <div class="p-2 bd-highlight align-items-center"><h6><b>Creado por: {foro.author}</b></h6></div>
            <div class="p-2 bd-highlight align-items-center"><h6><b><Moment format="DD/MM/YYYY hh:mm:ss">{foro.createdAt}</Moment></b></h6></div>
            </div>    
            <Row>
                <Col lg={2}></Col>
                <Col lg={8} className='d-flex justify-content-center align-items-center'>
                    <span className='my-2 text-initial fs-4 fw-bolder'><b>{foro.comment}</b></span>
                </Col>
                <Col lg={2}></Col>
            </Row>
            <div class='row'>
                <div class='col-sm-12 d-flex justify-content-center align-items-center mb-1'>
                    <span className='mb-3 mt-2 text-warning fs-5 fw-bolder'><strong>Quieres participar... por favor deja tus comentarios</strong></span>
                </div>
                <hr className='text-info'/>
            </div>
            

            <div class='row'>
                        <div class='d-flex flex-row bd-highlight justify-content-center align-items-center'>
                            <div class="col-lg-8 d-flex justify-content-center align-items-center">
                                {foro.reviews.length === 0 && <Message variant='info'><strong>No hay comentarios</strong></Message>}                        
                            </div>
                        </div>

                        {foro.reviews.map((review) => (
                        <div class='d-flex flex-row bd-highlight justify-content-center align-items-center'>
                             <div class="col-lg-5">
                                <div class="p-1 bd-highlight align-items-center"><h6><b>Por: {review.name}</b></h6></div>
                                <div class="p-1 bd-highlight align-items-center mb-2"><span className='text-info fs-5 fw-bolder'>{review.comment}</span></div>
                                <div class="p-1 bd-highlight align-items-center"><h6><b><Moment format="DD/MM/YYYY hh:mm:ss">{review.createdAt}</Moment></b></h6></div>
                                <strong><hr /></strong>
                            </div>
                        </div>
                        ))}
            </div>

            <div class='d-flex flex-row bd-highlight justify-content-center align-items-center'>
            <div class='col-lg-6'>
                    <ListGroup.Item className='colorMenu'>
                    <h4 className='text-info my-1 mb-3'>Escribe un comentario</h4>

                    {loadingForoReview && <Loader />}
                    {successForoReview && <Message variant='success'>Comentario Enviado</Message>}
                    {errorForoReview && <Message variant='danger'>{errorForoReview}</Message>}

                    {userInfo ? (
                        <Form onSubmit={submitHandler} >
                            <Form.Group controlId='comment'>
                                <Form.Control
                                    as='textarea'
                                    row='5'
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                >
                                </Form.Control>                               
                            </Form.Group>
                            <Button
                                disabled={loadingForoReview}
                                type='submit'
                                variant='info'
                                className='btn-md my-2 text-light mt-3'
                            >
                                Enviar Comentario
                            </Button>
                        </Form>
                    ) : (
                        <Message variant='info'>
                            Por favor <Link to='/login'><strong>Ingresar</strong></Link> para escribir comentario
                        </Message>
                    )}
                </ListGroup.Item>
                </div>
            </div>

        </div>
          )
        }
    </div>
  )
}


export default ForoDetailScreen
