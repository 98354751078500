import React, { useEffect, useState } from 'react';
import { Row, Col, Image, Card } from 'react-bootstrap';
import Moment from 'react-moment';
import { Link } from 'react-router-dom'
import ScrollAnimation from 'react-animate-on-scroll';

function Mooc({ mooc }) {

  useEffect(() => {

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    
}, [])

  return (
    <div class="mt-2">
    <div>
    <ScrollAnimation animateIn="fadeIn duration='2s' offset">  
    <div class="card mx-auto">
       <div class="content">
          <div class="content-overlay"></div>
            <img src={mooc.image} class="card-img-top content-image card-header" />     
          <div class="content-details fadeIn-bottom">
            <a href={mooc.link} target="_blank"><i class="fa fa-info-circle fa-2x" aria-hidden="true" style={{color: 'white'}}></i></a>                                   
          </div>
        </div>
        <div class="card-body">
            <h5 class="mt-0 py-2 text-center font-weight-bold mooc-title" style={{fontSize: 12}}><b>{mooc.heading}</b></h5>
        </div>
        <div class='card-footer'>
          <h6 class="mt-0 py-2 text-center font-weight-bold mooc-title text-info" style={{fontSize: 12}}>{mooc.timeline}</h6>
        </div>
        
    </div>
    </ScrollAnimation>
</div>


    </div>
  )
}

export default Mooc