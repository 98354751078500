export const MENTOR_LIST_REQUEST = 'MENTOR_LIST_REQUEST'
export const MENTOR_LIST_SUCCESS = 'MENTOR_LIST_SUCCESS'
export const MENTOR_LIST_FAIL = 'MENTOR_LIST_FAIL'

export const MENTOR_DETAILS_REQUEST = 'MENTOR_DETAILS_REQUEST'
export const MENTOR_DETAILS_SUCCESS = 'MENTOR_DETAILS_SUCCESS'
export const MENTOR_DETAILS_FAIL = 'MENTOR_DETAILS_FAIL'

export const MENTOR_DELETE_REQUEST = 'MENTOR_DELETE_REQUEST'
export const MENTOR_DELETE_SUCCESS = 'MENTOR_DELETE_SUCCESS'
export const MENTOR_DELETE_FAIL = 'MENTOR_DELETE_FAIL'

export const MENTOR_CREATE_REQUEST = 'MENTOR_CREATE_REQUEST'
export const MENTOR_CREATE_SUCCESS = 'MENTOR_CREATE_SUCCESS'
export const MENTOR_CREATE_FAIL = 'MENTOR_CREATE_FAIL'
export const MENTOR_CREATE_RESET = 'MENTOR_CREATE_RESET'

export const MENTOR_UPDATE_REQUEST = 'MENTOR_UPDATE_REQUEST'
export const MENTOR_UPDATE_SUCCESS = 'MENTOR_UPDATE_SUCCESS'
export const MENTOR_UPDATE_FAIL = 'MENTOR_UPDATE_FAIL'
export const MENTOR_UPDATE_RESET = 'MENTOR_UPDATE_RESET'