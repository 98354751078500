import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom';
import { Row, Col, Image, ListGroup, Button, Card, Form } from 'react-bootstrap';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { useParams, useNavigate } from 'react-router-dom';
import { listArticleDetails, createArticleDetail } from '../actions/articleActions'
import { ARTICLE_CREATE_DETAILS_RESET } from '../constants/articleConstants'
import Moment from 'react-moment';
//import EditorToolbar, { modules, formats } from "./EditToolbar";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';


function BlogDetailScreen({ match }) {
   

    const { id } = useParams();
    let navigate = useNavigate();
    const dispatch = useDispatch()

    const articleDetails = useSelector(state => state.articleDetails)
    const { error, loading, article } = articleDetails

    let keyword = useLocation().search

    useEffect(() => {
        dispatch(listArticleDetails(id))

        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

    }, [dispatch, id, keyword])


   return (
    <div className="section1 container-fluid">
        <Link className='btn btn-light mt-1 mb-4 mt-2 ' to='/blog'>Go Back</Link>
        {
            loading ? <Loader />
            : error ? <Message variant='danger'>{error}</Message>
            : (
            <div>
            <div class='row'>
                <div class='col-sm-12 d-flex justify-content-center align-items-center'>
                    <h3 className='my-1'><b>{article.name}</b></h3>
                </div>
            </div>
            <div class='row'>
                <div class='col-sm-12 d-flex justify-content-center align-items-center'>
                    <h4 className='my-1'><b>{article.title}</b></h4>
                </div>
            </div>
            <div class='row'>
            <div class='col-sm-12 d-flex justify-content-center align-items-center'>
                    <h5 className='my-1'><b>{article.subtitle}</b></h5>                    
                </div>
            </div>
            <div class='row'>
                <div class='col-sm-12 d-flex justify-content-center align-items-center'>
                    <h6 className='my-1'><b>{article.createby}</b></h6>
                 
                </div>
            </div>
            <div class='row'>
            <div class='col-sm-12 d-flex justify-content-center align-items-center'>
                <h6 className='my-1'><b>{article.timeline}</b></h6>                    
            </div>
            </div>
            <div class="d-flex flex-row bd-highlight justify-content-center align-items-center">
                <div class="p-1 bd-highlight align-items-center"><h6><b>Subido por: {article.author}</b></h6></div>
                <div class="p-1 bd-highlight align-items-center"><h6><b><Moment format="DD/MM/YYYY hh:mm:ss">{article.createdAt}</Moment></b></h6></div>
            </div>    
            <div class="d-flex flex-row bd-highlight justify-content-center align-items-center ">
                <div class="p-1 d-flex justify-content-center align-items-center col-12 text-initial p fs-1 fontrtf  ">
                    <ReactQuill 
                        theme="bubble"
                        readOnly = 'true'
                        value={article.comment_rtf}
                        className='fontrtf'

                      />
                    </div>
                </div>
            </div>


          )
        }
    </div>
  )
}

export default BlogDetailScreen