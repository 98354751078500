import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Button, Card, Form } from 'react-bootstrap';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { listForos, listForoDetails, createForoDetail } from '../actions/foroActions'
import Loader from '../components/Loader';
import Message from '../components/Message';
import Foro from '../components/Foro';
import { FORO_CREATE_DETAILS_RESET } from '../constants/foroConstants'
import image1 from '../assets/images/project-page/project-card.svg'


function ForoScreen({ match }) {
    const [toggle, setToggle] = useState(null);
    const dispatch = useDispatch()
    
    /*const blogList = useSelector(state => state.blogList)
    const {error, loading, blogs} = blogList*/

    const foroList = useSelector(state => state.foroList)
    const {error, loading, foros} = foroList
   
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    
    /*const blogDetailCreate = useSelector(state => state.blogDetailCreate)
    const {error: errorBlogDetail, loading: loadingBlogDetail, success: successBlogDetail} = blogDetailCreate*/
  
    const { id } = useParams();
    let navigate = useNavigate();
    let keyword = useLocation().search

    
    useEffect(() => {
       dispatch(listForos(keyword))

       window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
       
    }, [dispatch, keyword])

    /*const submitHandler = (e) => {
        e.preventDefault()
        dispatch(createBlogDetail(id, {
            rating,
            comment
        }))
    }*/

    return (
      <div className="container-fluid">
          <Link className='btn btn-light' to='/' >Go Back</Link>

          <section>
          <div class="px-3 pt-5">
            <div class="row">
              <div
                class="col-md-6 d-flex flex-column text-center justify-content-center animate__animated animate__zoomIn animate__delay-1s">
                <h1 class="main-heading mb-2 head-upper text-info mt-2">Foro</h1>
                <p class="pre-heading p-upper font-weight-bolder mb-0 mt-3 text-center animate__animated animate__zoomIn animate__delay-2s fs-5 fw-bold"
                  >
                  En términos generales un foro de discusión puede definirse como un espacio de encuentro (físico o virtual) 
                  entre diversos participantes con el objetivo de intercambiar opiniones, plantear preguntas en torno a un 
                  tema o subtemas de interés común, así como compartir habilidades, experiencias o respuestas a preguntas 
                  entre sus participantes, lo cual genera nuevo conocimiento.
                </p>
              </div>
    
              <div class="col-md-6">
                <img class="img-fluid d-block img-fluid d-block animate__animated animate__zoomIn animate__delay-1s" src={image1} />
              </div>
            </div>
          </div>
        </section>


              {loading ? 
                <Loader /> 
                : error ? <Message variant='danger'>{error}</Message>
                :
                <div>
                  <Row className='mx-1'>
                  {foros.length === 0 ? <h4><b>No existen Foros actualmente</b></h4> : ''}
                  <p className='text-bold mb-4 fs-5 text-danger text-center mt-5 animate__animated animate__zoomIn animate__delay-1s'>Click en el tema</p>
                    {foros.map(foro => (
                       <Col key={foro._id} md={12}>
                          <Foro foro={foro} />
                       </Col>                        
                  ))}
                  </Row>

                </div>
              }
          </div>
            )

        }


export default ForoScreen
