import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Button, Card, Form } from 'react-bootstrap';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Loader from '../components/Loader'
import Message from '../components/Message'
import { listProjects } from '../actions/projectActions'
import image1 from '../assets/images/experience-page/cursos.jpg'
import image2 from '../assets/images/freelance-work.png'
import image3 from '../assets/images/html.png'
import image4 from '../assets/images/css.png'
import image5 from '../assets/images/js.png'
import image6 from '../assets/images/react.png'
import image7 from '../assets/images/aws.png'
import Project from '../components/Project';
import ScrollAnimation from 'react-animate-on-scroll';


function CourseScreen() {

  const dispatch = useDispatch()

  let navigate = useNavigate();
  let keyword = useLocation().search

  const projectList = useSelector(state => state.projectList)
  const { error, loading, projects } = projectList


  useEffect(() => {
    dispatch(listProjects(keyword))

     window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
     
  }, [dispatch, keyword])

  const temario = () => {
    alert('Contáctame para enviarte el temario. Gracias por el interes.')
  }
  
  return (

    <div>
    <Link to='/' className='btn btn-light mt-2'>Go Back</Link>

    <section >
    <div class="row text-dark">
    <div
      class="col-md-6 d-flex mt-5 flex-column text-center justify-content-center animate__animated animate__zoomIn animate__delay-1s">
      <h1 class="main-heading mb-0 text-center text-info mb-1">Cursos y Diplomados</h1>
      <p class="pre-heading font-weight-bolder mb-0 mt-3 text-center animate__animated animate__zoomIn animate__delay-1s h5" >
            El mejor experto también fue un día aprendiz... <br />
            <br />
            El gran objetivo del aprendizaje no es el conocimiento, sino la acción. <br /> <br /><span className='text-info fs-6'>- Herbet Spencer.</span>
      </p>
    </div>
    <div class="col-md-6">
      <img class="img-header img-fluid d-block img-fluid d-block  animate__animated animate__zoomIn animate__delay-1s" src={image1} />
    </div>
  </div>
    </section>

    <section>

      <Row className='mt-5'>
        <Col md={12}>
          <h1 class="text-center exp-head heading1 my-4 animate__animated animate__zoomIn animate__delay-1s formal-edu text-info main-heading">Cursos de Desarollo Web</h1>      
          <p className='fs-3 text-dark text-center fw-bold animate__animated animate__zoomIn animate__delay-1s'>¿Sabes que significa Desarollador Full Stack? Has querido programar, pero son demasiadas opciones para aprender?</p>
        </Col>
      </Row>
      <Row className='d-flex justify-content-center align-items-center'>
        <Col md={10}>
          <p className='fs-3 text-dark text-center fw-bold animate__animated animate__zoomIn animate__delay-1s'>¿Sabías que en México hay alrededor de 5 millones de empresas, pero solo 10% tiene presencia en línea?</p>
          <p className='fs-5 fw-bold text-dark text-center animate__animated animate__zoomIn animate__delay-1s'>
          Un <span className='fw-bold fs-5 text-info'>Desarrollador Web Full Stack</span> es ese profesional que tiene experiencia en todos los aspectos o 'stacks' del desarrollo web,
          desde el diseño, la codificación frontend, la codificación backend, experiencia en hosting, base de datos, entre otras.
          </p>
        </Col>
      </Row>
      <hr className='text-info'/>
      <Row className='mt-2'>
        <Col md={4}>
          <p className='fs-3 text-dark text-center fw-bolder'>¿Sabes cual es el sueldo anual de un desarollador Full Stack en EUA?</p>
          <p className='fs-5 text-center text-danger fw-bold mt-4'><a href='https://www.simplilearn.com/average-full-stack-developer-salary-article' target='_blank' className='mt-2 text-danger'>Promedio sueldo base anual $101,537.00 USD</a></p>          
          <p className='fs-5 text-center text-danger fw-bold mt-4'>Promedio mensual $8,500.00 USD</p>
          <p className='fs-4 text-center text-info fw-bold mt-4'>3 proyectos a realizar, entre ellos un e-commerce super completo!</p>
        </Col>
        <Col md={4}>
          <p className='fs-3 text-dark text-center fw-bolder'>¿Te gustaría realmente aprenderlo?</p>
          <p className='fs-3 text-center text-info fw-bolder'>Desde cero hasta master</p>          
          <p className='fs-5 text-center text-info fw-bold'>Empieza este 2023 aprendiendo a programar y poder ser elegible en una empresa, o tener tus propios clientes</p>
          <p className='fs-4 text-center text-danger fw-bold'><mark>!Este portafolio es un ejemplo de un proyecto a realizar durante el curso!</mark></p>          
        </Col>
        <Col md={4}>
          <p className='fs-5 text-danger text-center fw-bold'>!Se que hay un montón de cursos en el mercado! La diferencia conmigo es que te aseguro, aprenderas!</p>
          <p className='fs-5 text-center text-success fw-bold'><mark>Nada de Frameworks como WordPress, Hostinger o otros. En este curso aprenderas tecnologías como:</mark></p>
          <p className='fs-5 text-center text-success fw-bold'>HTML, CSS, Bootstrap, Javascript, React, Redux, Git, Python, Django y mucho más.</p>
          <p className='fs-5 text-center text-info fw-bold'>Aprenderás 4 lenguajes de programación, y frameworks como React.</p>
        </Col>
      </Row>
      <hr className='text-info'/>
    </section>

    <section className='mt-5'>
    
        <Row className='mt-4'>
          <Col md={6}>
              <h1 className='mt-4 fw-bold border border-info border-3 p-3 text-dark text-center'>Curso en desarollo de software Full Stack</h1>
              <h6 className='mt-4 mb-4 fw-bold'>20 semanas | Online con mentor | Basado en Proyectos </h6>
              <p as='button' className='btn btn-info text-light my-2' onClick={temario}>Bajar temario pdf</p>
              <p className='my-2'><Link to='/article/8' className='text-success fw-bold'>Articulo en mi Blog acerca del Camino para ser Full Stack Developer</Link></p>
              <p className='text-info mt-4 fs-5 fw-bolder'>Para fechas y costos, <Link to='/contact'>contactáctame</Link>, de inmediato te constestaré.</p>
          </Col>
          <Col md={6}>
              <Image src={image2} className='my-2 img-fluid h-100' alt='Imagen' />
          </Col>
        </Row>
        <hr className='text-info'/>
        <Row className='mt-4'>
            <Col md={6}>
              <h2>Aprende las tecnologías mas demandadas</h2>
              <h4>Ultimas versiones al 2022</h4>              
              <p className='my-2 mb-2 fs-5 fw-bold text-success'>Desde el primer día sentirás la confianza de estar aprendiendo.</p>
              <p className='my-2 mb-2 fs-5 fw-bold text-danger'>Te lo aseguro!</p>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={2}>
                  <Image src={image3} image-fluid alt='Imagen' />                
                </Col>
                <Col md={2}>
                  <Image src={image4} image-fluid alt='Imagen' />                
                </Col>
                <Col md={2}>
                  <Image src={image5} image-fluid alt='Imagen' />                
                </Col>
                <Col md={3}>
                  <Image src={image6} image-fluid alt='Imagen' />                
                </Col>                
                <Col md={3}>
                  <Image src={image7} image-fluid alt='Imagen' />                
                </Col>                
              </Row>
            </Col>
          </Row>
    </section>
    <hr className='text-info'/>
    <section className='my-4'>
      <ScrollAnimation animateIn="fadeIn duration='2s' offset">
        <div class='row'>
          <div class='col-md-6'>
          <Card className='mb-3 border border-info border-3 rounded h-100 imageprofile animate__animated animate__fadeInUp animate__delay-1s card'>
            <Card.Header className='bg-info'>
              <p className='text-center fs-2 text-light mt-2'>Curso para Frontend</p>
            </Card.Header>
                  <Card.Body>
                     <ul>
                        <li className='fw-bold fs-4'>HTML</li>
                        <li className='fw-bold fs-4'>CSS</li>
                        <li className='fw-bold fs-4'>Bootstrap</li>
                        <li className='fw-bold fs-4'>Javascript</li>
                        <li className='fw-bold fs-4'>React</li>
                        <li className='fw-bold fs-4'>Redux</li>
                        <li className='fw-bold fs-4'>Responsive</li>
                        <li className='fw-bold fs-4'>Uso de Visual Studio Code</li>                                                                                                                                                
                     </ul>
                     <Card.Text className='text-center h4'>En linea</Card.Text>
                     <Card.Text className='text-center h6'><strong>Período:</strong>5 semanas</Card.Text>                    
                  </Card.Body>
                <Card.Footer as='div' className='bg-info'>
                <div class="d-flex flex-row bd-highlight justify-content-center align-items-center">
                  <p className='fs-4 text-light mt-2'><b>Aprenderás todo el frontend!</b></p>
                </div>
                </Card.Footer>
                </Card>
            </div>

       <div class='col-md-6'>
       <Card className='mb-3 border border-info border-3 rounded h-100 imageprofile animate__animated animate__fadeInUp animate__delay-1s card'>
         <Card.Header className='bg-info'>
          <p className='text-center fs-2 text-light mt-2'>Curso para Backend</p>
         </Card.Header>
               <Card.Body>
                  <ul>
                     <li className='fw-bold fs-4'>Python</li>
                     <li className='fw-bold fs-4'>Django</li>
                     <li className='fw-bold fs-4'>Django Rest Framework</li>
                     <li className='fw-bold fs-4'>Sqlite</li>
                     <li className='fw-bold fs-4'>PostgreSQL</li>
                     <li className='fw-bold fs-4'>Creación de API Rest</li>
                     <li className='fw-bold fs-4'>Queries</li>
                     <li className='fw-bold fs-4'>Postman</li>
                     <li className='fw-bold fs-4'>CRUD</li>
                     <li className='fw-bold fs-4'>Usuario y Autentificación</li>                                                                                    
                  </ul>
                  <Card.Text className='text-center h4 mt-2'>En linea</Card.Text>
                  <Card.Text className='text-center h6 mt-2'><strong>Período:</strong>5 semanas</Card.Text>                     
               </Card.Body>
             <Card.Footer as='div' className='bg-info'>
             <div class="d-flex flex-row bd-highlight justify-content-center align-items-center">
                   <p className='fs-4 text-light mt-2'><b>Aprenderás todo el backend!</b></p>
             </div>
             </Card.Footer>
             </Card>
         </div>
      </div>
      <div class='row my-4'>
        <div class='col-md-12'>
        <Card className='mb-3 border border-info border-3 rounded h-100 imageprofile animate__animated animate__fadeInUp animate__delay-1s card'>
        <Card.Header className='bg-info'>
          <p className='text-center fs-2 text-light mt-2'>Curso en desarollo de software Full Stack</p>
        </Card.Header>
              <Card.Body >
                <Row>
                  <Col md={4}>
                    <p className='fs-4 text-info fw-bold text-start'>Frontend</p>
                    <ul>
                    <li className='fw-bold fs-4'>HTML</li>
                    <li className='fw-bold fs-4'>CSS</li>
                    <li className='fw-bold fs-4'>Bootstrap</li>
                    <li className='fw-bold fs-4'>Javascript</li>
                    <li className='fw-bold fs-4'>React</li>
                    <li className='fw-bold fs-4'>Redux</li>
                    <li className='fw-bold fs-4'>Responsive</li>
                    <li className='fw-bold fs-4'>Uso de Visual Studio Code como IDE</li>                                        
                    </ul>
                  </Col>
                  <Col md={4}>
                  <ul>
                  <p className='fs-4 text-info fw-bold text-start'>Backend</p>
                    <li className='fw-bold fs-4'>Python</li>
                    <li className='fw-bold fs-4'>Django</li>
                    <li className='fw-bold fs-4'>Django Rest Framework</li>
                    <li className='fw-bold fs-4'>Sqlite</li>
                    <li className='fw-bold fs-4'>PostgreSQL</li>
                    <li className='fw-bold fs-4'>Creación de API Rest</li>
                    <li className='fw-bold fs-4'>Queries</li>
                    <li className='fw-bold fs-4'>Postman</li>
                    <li className='fw-bold fs-4'>CRUD</li>
                    <li className='fw-bold fs-4'>Usuario y Autentificación</li>                                        
                  </ul>
                  </Col>
                  <Col md={4}>
                  <p className='fs-4 text-info fw-bold text-start'>Deploy</p>
                  <ul>
                    <li className='fw-bold fs-4'>Git</li>
                    <li className='fw-bold fs-4'>Heroku</li>
                    <li className='fw-bold fs-4'>Digital Ocean</li>
                    <li className='fw-bold fs-4'>Servicios de Amazon AWS</li>                    
                    <li className='fw-bold fs-4'>Hosting</li>
                    <li className='fw-bold fs-4'>SSL</li>
                    <li className='fw-bold fs-4'>Docker</li>
                    <li className='fw-bold fs-4'>Testing</li>
                    <li className='fw-bold fs-4'>CI/CD pipeline</li>                   
                  </ul>
                  </Col>
                </Row>
                 <Card.Text className='text-center h4 mt-4'>En linea</Card.Text>
                 <Card.Text className='text-center h6 mt-2 mb-0'><strong>Período:</strong>20 semanas</Card.Text>                     
              </Card.Body>
            <Card.Footer as='div' className='bg-info'>
            <div class="d-flex flex-row bd-highlight justify-content-center align-items-center">
                  <p className='fs-4 text-light mt-2'><b>Aprenderás desde cero hasta producción.</b></p>
            </div>
            </Card.Footer>
            </Card>

        </div>      
      </div>

    </ScrollAnimation>
    </section>
    <section className='my-4'>
    <ScrollAnimation animateIn="fadeIn duration='2s' offset">
    <div class='row'>
      <div class='col-md-6'>
      <Card className='mb-3 border border-info border-2 rounded h-100 imageprofile animate__animated animate__fadeInUp animate__delay-1s card' style={{backgroundColor: '#bbdde6'}}>
        <Card.Header style={{backgroundColor: '#cbd3ea'}}>
          <p className='text-center fs-2 text-dark mt-2 fw-bold'>Curso Lenguaje C</p>
        </Card.Header>
              <Card.Body>
                 <ul>
                    <li className='fw-bold fs-4'>Instalación C y Compilador </li>
                    <li className='fw-bold fs-4'>Visual Studio Code para programar en C</li>
                    <li className='fw-bold fs-4'>Variables, Constantes y Keywords</li>
                    <li className='fw-bold fs-4'>Instrucciones y Operadores</li>
                    <li className='fw-bold fs-4'>Condicionales</li>
                    <li className='fw-bold fs-4'>Ciclos</li>
                    <li className='fw-bold fs-4'>Funciones y Recursión</li>
                    <li className='fw-bold fs-4'>Apuntadores</li>
                    <li className='fw-bold fs-4'>Arreglos</li>
                    <li className='fw-bold fs-4'>Strings</li>
                    <li className='fw-bold fs-4'>File I/O</li>
                    <li className='fw-bold fs-4'>Asignación de memoria dinámica</li>
                    <li className='fw-bold fs-4'>Al final, dos proyectos super completos.</li>                    
                 </ul>
                 <Card.Text className='text-center h4'>En linea</Card.Text>
                 <Card.Text className='text-center h6'><strong>Período:</strong>8 semanas</Card.Text>                    
              </Card.Body>
            <Card.Footer as='div' style={{backgroundColor: '#cbd3ea'}}>
              <div class="d-flex flex-row bd-highlight justify-content-center align-items-center">
                <p className='fs-4 text-dark mt-2'><b>Con muchos ejercicios y prácticas</b></p>
              </div>
            </Card.Footer>
            </Card>
        </div>

   <div class='col-md-6'>
   <Card className='mb-3 border border-info border-2 rounded h-100 imageprofile animate__animated animate__fadeInUp animate__delay-1s card' style={{backgroundColor: '#bbdde6'}}>
     <Card.Header style={{backgroundColor: '#cbd3ea'}}>
      <p className='text-center fs-2 text-dark mt-2 fw-bold'>Curso Lenguaje C#</p>
     </Card.Header>
           <Card.Body>
              <ul>
                <li className='fw-bold fs-4'>Intalación Visual Studio Code, .NET Desktop Dev y Unity para juegos</li>
                <li className='fw-bold fs-4'>Instrucciones y Operadores</li>
                <li className='fw-bold fs-4'>Condicionales</li>
                <li className='fw-bold fs-4'>Ciclos</li>
                <li className='fw-bold fs-4'>Funciones y Recursión</li>
                <li className='fw-bold fs-4'>Apuntadores</li>
                <li className='fw-bold fs-4'>Arreglos</li>
                <li className='fw-bold fs-4'>Métodos</li>
                <li className='fw-bold fs-4'>Clases</li>
                <li className='fw-bold fs-4'>Objetos</li>
                <li className='fw-bold fs-4'>Arreglo de Objetos</li>
                <li className='fw-bold fs-4'>Interfases</li>
                <li className='fw-bold fs-4'>Listas</li>
                <li className='fw-bold fs-4'>multithreading</li>                                                                                                                                    
                <li className='fw-bold fs-4'>5 proyectos a realizar</li>                
              </ul>
              <Card.Text className='text-center h4 mt-2'>En linea</Card.Text>
              <Card.Text className='text-center h6 mt-2'><strong>Período:</strong>12 semanas</Card.Text>                     
           </Card.Body>
         <Card.Footer as='div' style={{backgroundColor: '#cbd3ea'}}>
         <div class="d-flex flex-row bd-highlight justify-content-center align-items-center">
          <p className='fs-4 text-dark mt-2'><b>Para aplicaciones de escritorio, web services y juegos!</b></p>
         </div>
        </Card.Footer>
         </Card>
     </div>
    </div>
  </ScrollAnimation>    
    
    
   </section>
  <section>
      <Row className='mt-5'>
        <Col md={12}>
          <h1 class="text-center exp-head heading1 my-4 animate__animated animate__zoomIn animate__delay-1s formal-edu text-dark main-heading border border-info border-3 p-3">Diplomado en Tecnologías de Información</h1>      
          <p className='fs-3 text-dark text-center fw-bold animate__animated animate__zoomIn animate__delay-1s'>Con el avance de los sistemas digitales y las redes de comunicaciones para el almacenamiento, proceso y transferencia de información, la sociedad y el mundo se han transformado de una manera acelerada y definitiva.</p>
        </Col>
      </Row>
      <Row className='d-flex justify-content-center align-items-center'>
        <Col md={10}>
          <p className='fs-3 text-success text-center fw-bold animate__animated animate__zoomIn animate__delay-1s'>Actualmente ninguna actividad de la sociedad humana se concibe sin estos elementos. Es por ello que, en la era actual, las Tecnologías de Información juegan un papel tan importante como en su momento lo fue la palabra hablada y posteriormente escrita e impresa.</p>
          <p className='fs-3 fw-bold text-dark text-center animate__animated animate__zoomIn animate__delay-1s'>
            En nuestro país, cada día son mayores los requerimientos de personal con conocimientos en estas áreas vitales para el funcionamiento de toda empresa o institución, sea cual fuere su tipo o función.
          </p>
        </Col>
      </Row>
      <hr className='text-info'/>
      <Row className='mt-2'>
        <Col md={12}>
          <p className='fs-3 text-dark text-center fw-bolder'><mark>El Diplomado en Tecnologías de Información (DTI) surge como una opción de capacitación para diversos ámbitos profesionales en donde la información digital y su manejo constituye un factor determinante. El Diplomado en Tecnologías de Información tiene los siguientes objetivos:</mark></p>
          <ul>
            <li className='fw-bold fs-4'>Formar recursos humanos capacitados en los principales tópicos que involucran las Tecnologías de Información (TI).</li>
            <li className='fw-bold fs-4'>Ofrecer capacitación de alto nivel a amplios sectores profesionales de la sociedad que usan y requieren TI.</li>
            <li className='fw-bold fs-4'>Actualizar los conocimientos de profesionales de la información y sus áreas relacionadas.</li>
            <li className='fw-bold fs-4'>Homologar y uniformizar conocimientos en TI del personal de aquellas empresas e instituciones que así lo requieran.Dirigido a: * Profesionales de la información y sus áreas relacionadas.</li>
            <li className='fw-bold fs-4'>Profesionales de todas las áreas que usen y requieran conocimientos en TI.</li>
            <li className='fw-bold fs-4'>Personal técnico y administrativo relacionada con el uso de TI.</li>
            <li className='fw-bold fs-4'>Toda persona con necesidad de conocimientos técnicos y prácticos en TI.</li>
            <li className='fw-bold fs-4'>Estudiantes de todos los niveles y carreras que requieran homologar y/o actualizar sus conocimientos en TI.</li>                                                                                                                                                
          </ul>
        </Col>
      </Row>
    </section>

    <hr className='text-info'/>
    <section className='my-4'>
      <ScrollAnimation animateIn="fadeIn duration='2s' offset">
      <div class='row my-4'>
        <div class='col-md-12'>
        <Card className='mb-3 border border-warning border-3 rounded h-100 imageprofile animate__animated animate__fadeInUp animate__delay-1s card'>
        <Card.Header className='bg-success'>
          <p className='text-center fs-2 text-light mt-2'>Plan de Estudios Diplomado en Tecnologías de Información</p>
        </Card.Header>
              <Card.Body >
                <Row>
                  <Col md={12}>
                    <ul className="list-group list-group-numbered">
                      <li className='fw-bold fs-4 list-group-item'>Introducción y Conceptos Básicos (15 hrs)</li>
                      <li className='fw-bold fs-4 list-group-item'>Sistemas Operativos (15 hrs)</li>
                      <li className='fw-bold fs-4 list-group-item'>Seguridad Informática (25 hrs)</li>
                      <li className='fw-bold fs-4 list-group-item'>Redes y Telecomunicaciones (20 hrs)</li>
                      <li className='fw-bold fs-4 list-group-item'>Redes Inalámbricas y Cómputo Móvil (20 hrs)</li>
                      <li className='fw-bold fs-4 list-group-item'>Tecnologías de Programación Python (25 hrs)</li>
                      <li className='fw-bold fs-4 list-group-item'>Análisis y Diseño de Sistemas (20 hrs)</li>
                      <li className='fw-bold fs-4 list-group-item'>Desarrollo de Aplicaciones en Internet (20 hrs)</li>
                      <li className='fw-bold fs-4 list-group-item'>Bases de Datos y SQL(20 hrs)</li>
                      <li className='fw-bold fs-4 list-group-item'>Minería de Datos y Datawarehouse (15 hrs)</li>
                      <li className='fw-bold fs-4 list-group-item'>Multimedia y Manejo Digital de Imágenes (15 hrs)</li>
                      <li className='fw-bold fs-4 list-group-item'>Comercio Electrónico (15 hrs)</li>                                                                                                                                  
                      <li className='fw-bold fs-4 list-group-item'>Administración de Proyectos de TI (15 hrs)</li>                                                                                                                                  
                    </ul>
                  </Col>
                </Row>
                     
              </Card.Body>
            <Card.Footer as='div' className='bg-success'>
            <div class="d-flex flex-row bd-highlight justify-content-center align-items-center">
                  <p className='fs-4 text-light mt-2'><b>Para mas información y costos del diplomado, <Link to='/contact' className='text-decoration-none text-warning'><b>contactáctame.</b></Link></b></p>
            </div>
            </Card.Footer>
            </Card>

            
        </div>
        <hr className='text-info'/>
        <div class='col-md-12'>
        <Card className='mb-3 border border-warning border-3 rounded h-100 imageprofile animate__animated animate__fadeInUp animate__delay-1s card'>
        <Card.Header className='bg-success'>
          <p className='text-center fs-2 text-light mt-2'>Requisitos Diplomado en Tecnologías de Información</p>
        </Card.Header>
          
               <Card.Body >
               <Card.Text className='text-start h4 mt-4 text-danger'>Requisitos de admisión</Card.Text>
                <Row>
                  <Col md={12}>
                    <ul className="list-group list-group-flush">
                      <li className='fw-bold fs-4 list-group-item'>Licenciatura, carrera técnica, estudiantes de los últimos semestres de informática, comunicaciones, telecomunicaciones, ciencias básicas, ingenierías y carreras afines al cómputo.</li>
                      <li className='fw-bold fs-4 list-group-item'>Personas de cualquier formación con conocimientos básicos y/o experiencia en áreas de computación e informática.</li>
                      <li className='fw-bold fs-4 list-group-item'>Aprobar el examen de admisión, si el comité académico del diplomado lo considera necesario.</li>
                    </ul>
                  </Col>
                </Row>
                <Card.Text className='text-start h4 mt-4 text-danger'>Requisitos para obtener el diploma</Card.Text>
                <Row>
                  <Col md={12}>
                  <ul className="list-group list-group-flush">
                    <li className='fw-bold fs-4 list-group-item'>Aprobar todas y cada una de las evaluaciones de los módulos y obtener un promedio mínimo de 8.</li>
                    <li className='fw-bold fs-4 list-group-item'>No sumar más de 12 horas de inasistencias durante todo el diplomado.</li>
                    <li className='fw-bold fs-4 list-group-item'>Los alumnos que ejerzan algún tipo de beca, no podrán reprobar ningún módulo, no podrán faltar a ninguna sesión y deberán mantener durante todo el diplomado al menos 8.0 de promedio. de no cumplir con los anteriores requerimientos, serán dados de baja del diplomado.</li>
                  </ul>
                </Col>
                </Row>
                 <Card.Text className='text-center h6 mt-2 mb-0'><strong>Período:</strong>5 meses</Card.Text>                     
              </Card.Body>
            <Card.Footer as='div' className='bg-success'>
            <div class="d-flex flex-row bd-highlight justify-content-center align-items-center">
                  <p className='fs-4 text-light mt-2'><b>Para mas información y costos del diplomado, <Link to='/contact' className='text-decoration-none text-warning'><b>contactáctame.</b></Link></b></p>
            </div>
            </Card.Footer>
            </Card>
        </div>
      </div>

    </ScrollAnimation>
    </section>    
  </div>


  )
}

export default CourseScreen