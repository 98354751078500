import React, { useEffect, useState } from 'react';
import { Row, Col, Image, Card } from 'react-bootstrap';
import Moment from 'react-moment';
import { Link } from 'react-router-dom'
import ScrollAnimation from 'react-animate-on-scroll';

function BadgeC({ badge }) {

  useEffect(() => {

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    
}, [])

  return (
    
    <div class="my-2">
    <ScrollAnimation animateIn="fadeIn duration='3s' offset">
    <div className='border border-success rounded rounded-3 h-100'> 
  
      <img class="img-fluid d-block mb-1 mx-auto hvr-grow h-100" src={badge.image} alt="Card image cap" width="100" />
      <div class="text-center fw-bold p-2">
        {badge.heading}
      </div>
      <div class="text-center text-success font-weight-bold p-2">{badge.timeline}</div>


    </div>

    </ScrollAnimation>

    </div>
  )
}

export default BadgeC