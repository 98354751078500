import React, { useEffect, useState } from 'react';
import { Row, Col, Image, Card } from 'react-bootstrap';
import Moment from 'react-moment';
import { Link } from 'react-router-dom'
import ScrollAnimation from 'react-animate-on-scroll';

function Service({ service }) {

  useEffect(() => {

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    
}, [])

  return (
    <div class="mt-2">
    <div>
    <ScrollAnimation animateIn="fadeIn duration='2s' offset">  
    <Card className='mb-3 border border-info border-3 rounded h-100 imageprofile animate__animated animate__fadeInUp animate__delay-1s card'>
        <div class='row'>
          <div class='col-md-5'>
              <Card.Img src={service.image} className="card-img-top my-2 img-fluid h-100" alt="Imagen" />
          </div>
          <div class='col-md-7'>
            <Card.Header className='bg-info'>
              <h3 className='text-center text-light mt-2'>{service.name}</h3>
            </Card.Header>
                  <Card.Body>
                     <ul>
                        {service.service1 ? <li>{service.service1}</li> : ''}
                        {service.service2 ? <li>{service.service2}</li> : ''}
                        {service.service3 ? <li>{service.service3}</li> : ''}
                        {service.service4 ? <li>{service.service4}</li> : ''}
                        {service.service5 ? <li>{service.service5}</li> : ''}                                                                        
                     </ul>
                  </Card.Body>
                <Card.Footer as='div'>
                <div class="d-flex flex-row bd-highlight justify-content-center align-items-center">
                      <p className='fs-5 text-info fw-bolder mt-2'><b>{service.comment}</b></p>
                </div>
                </Card.Footer>
            </div>
       </div>
  </Card>
    </ScrollAnimation>
</div>


    </div>
  )
}

export default Service