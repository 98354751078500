import React, { useEffect} from 'react';
import { Card, Image, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
//import Rating from './Rating';
import { Link } from 'react-router-dom'
import Moment from 'react-moment';
import ScrollAnimation from 'react-animate-on-scroll';


function Foro({ foro }) {

  const id = foro._id
  const dispatch = useDispatch()

   
  let keyword = useLocation().search

  useEffect(() => {

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    
}, [dispatch, keyword])

  return (
    <section className='container-fluid my-2'>
    <ScrollAnimation animateIn="fadeIn duration='2s' offset">
      <Row key={foro._id}>
        <Col md={5}>
            <Link to={`/foros/${foro._id}`} className='text-decoration-none'>
                <p className='d-flex justify-content-center align-items-center mx-0 fs-4'><strong>{foro.name}</strong> </p>
            </Link>
        </Col>
        <Col md={4}>
            <p className='text-info d-flex justify-content-center align-items-center fs-5'><b>{foro.comment}</b></p>
        </Col>
        <Col md={3}>
            <h6 className='text-info d-flex justify-content-center align-items-center mx-0'>Creado por: <span className='text-success'>{foro.author}</span></h6>
        </Col>
      </Row>
      <hr className='text-info'/>
      </ScrollAnimation>
    </section>

  )
}

export default Foro
