import React from 'react'
import { Row, Col, Image, ListGroup, Button, Card, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import image1 from '../assets/images/office.jpg'



function HomeScreen() {
  return (
    <div>
    <section className='container-fluid mt-0'>
      <Row>
        <Col lg={5} className='my-2'>
          <p className='text-start text-info text-initial mt-3 fs-1 titleprin'><b>Hola 👋, amigos <i class="fa fa-check" aria-hidden="true"></i></b> <span><hr /></span></p>
          <Row>
            <Col lg={12}>
              <p className='text-start text-info fs-3 fw-bolder'>Soy Eduardo. <Link to='/service' className='text-decoration-none text-success'><b>Desarollador web,</b> </Link>
              <Link to='/service' className='text-decoration-none text-success'><b>integrador Inteligencia de Negocios</b></Link> e imparto <Link to='/course' className='text-decoration-none text-success'> <b>cursos</b></Link> de
              tecnología. <i class="fa-solid fa-code text-info "></i></p>
            </Col>
          </Row>
          <Row>
            <Col lg={12} >
              <p className='text-start text-info fs-3 mb-0 fw-bolder'>En este sitio podrás <Link to='/about' className='text-decoration-none text-success'><b>conocerme,</b></Link> <Link to='/blog' className='text-decoration-none text-success'> <b>seguirme,</b> </Link>
              <Link to='/foro' className='text-decoration-none text-success'><b>comentar</b></Link> y descubrir un montón de cosas de mí. Te invito a descubrirlo! <i class="fa-regular fa-keyboard text-info fw-bolder"></i></p>
            </Col>
          </Row>
          <hr className='text-info'/>
          <Row>
            <Col md={6} sm={8} className='d-flex justify-centent-center align-items-center mt-0'>
              <p className='text-start text-info fs-3 fw-bolder'><Link to='/contact' className='text-decoration-none text-success'><b>Contáctame</b></Link> <i class="fa-solid fa-address-book text-info fw-bolder"></i></p>
            </Col>
            <Col md={6} sm={8} className='d-flex justify-centent-center align-items-center'>
                    <p className='fs-4'><a class="social-icon facebook text-info" href="https://facebook.com/eduardo.villalpando.14019338" target="_blank" rel="author">
                      <i class="fab fa-facebook-f fa-md fa-fw"></i></a></p>
                    <p className='fs-4 p-2'><a class="social-icon twitter text-info" href="https://twitter.com/Eduardo49353622" target="_blank" rel="author" >
                      <i class="fab fa-twitter"></i></a></p>
                    <p className='fs-4 p-2'><a class="social-icon linkedin text-info" href="https://www.linkedin.com/in/eduardo-villalpando-9b7494130/" target="_blank" rel="author">
                      <i class="fab fa-linkedin-in"></i>
                    </a></p>
                    <p className='fs-4 p-2'><a class="social-icon dribbble text-info" href="https://dribbble.com/villalpe5" target="_blank" rel="author">
                      <i class="fab fa-dribbble"></i>
                    </a></p>
                    <p className='fs-4 p-2'><a class="social-icon medium text-info" href="https://medium.com/@villalpe5" target="_blank" rel="author">
                      <i class="fab fa-medium-m"></i></a></p>
              </Col>
          </Row>

        </Col>
        <Col lg={7} className='my-2'>
        <Card className='border border-info border-2 rounded '>
          <Card.Img src={image1} className="card-img-top my-2 img-fluid h-100" alt="Imagen" />
        </Card>
        </Col>
      </Row>

      <hr className='text-info'/>

    
    </section>
    </div>

  )
}

export default HomeScreen
