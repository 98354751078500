export const EXPIRIENCE_LIST_REQUEST = 'EXPIRIENCE_LIST_REQUEST'
export const EXPIRIENCE_LIST_SUCCESS = 'EXPIRIENCE_LIST_SUCCESS'
export const EXPIRIENCE_LIST_FAIL = 'EXPIRIENCE_LIST_FAIL'

export const EXPIRIENCE_DETAILS_REQUEST = 'EXPIRIENCE_DETAILS_REQUEST'
export const EXPIRIENCE_DETAILS_SUCCESS = 'EXPIRIENCE_DETAILS_SUCCESS'
export const EXPIRIENCE_DETAILS_FAIL = 'EXPIRIENCE_DETAILS_FAIL'

export const EXPIRIENCE_DELETE_REQUEST = 'EXPIRIENCE_DELETE_REQUEST'
export const EXPIRIENCE_DELETE_SUCCESS = 'EXPIRIENCE_DELETE_SUCCESS'
export const EXPIRIENCE_DELETE_FAIL = 'EXPIRIENCE_DELETE_FAIL'

export const EXPIRIENCE_CREATE_REQUEST = 'EXPIRIENCE_CREATE_REQUEST'
export const EXPIRIENCE_CREATE_SUCCESS = 'EXPIRIENCE_CREATE_SUCCESS'
export const EXPIRIENCE_CREATE_FAIL = 'EXPIRIENCE_CREATE_FAIL'
export const EXPIRIENCE_CREATE_RESET = 'EXPIRIENCE_CREATE_RESET'

export const EXPIRIENCE_UPDATE_REQUEST = 'EXPIRIENCE_UPDATE_REQUEST'
export const EXPIRIENCE_UPDATE_SUCCESS = 'EXPIRIENCE_UPDATE_SUCCESS'
export const EXPIRIENCE_UPDATE_FAIL = 'EXPIRIENCE_UPDATE_FAIL'
export const EXPIRIENCE_UPDATE_RESET = 'EXPIRIENCE_UPDATE_RESET'