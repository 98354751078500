import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Button, Row, Col } from 'react-bootstrap';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { listForos, deleteForo, createForo } from '../actions/foroActions'
import { FORO_CREATE_RESET } from '../constants/foroConstants'
import Moment from 'react-moment';

function ForoListScreen() {

  const dispatch = useDispatch()
  const navigate = useNavigate();
  
  const foroList = useSelector(state => state.foroList)
  const { loading, error, foros } = foroList

  const foroDelete = useSelector(state => state.foroDelete)
  const { loading: loadingDelete, success: successDelete, error: errorDelete } = foroDelete

  const foroCreate = useSelector(state => state.foroCreate)
  const { loading: loadingCreate, success: successCreate, error: errorCreate, foro: createdForo } = foroCreate

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  let keyword = useLocation().search

  useEffect(() => {
    dispatch({ type: FORO_CREATE_RESET })
    if(!userInfo){
      navigate('/login')
    }

    if(successCreate){
        navigate(`/admin/foro/${createdForo._id}/edit`)
    }else{
      dispatch(listForos(keyword))
    }

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

  }, [dispatch, navigate, userInfo, successDelete, successCreate, createdForo, keyword])

  const deleteHandler = (id) => {
    if(window.confirm('Estas seguro de borrar este blog?')){
      dispatch(deleteForo(id))
    }
  }

  const createForoHandler = () => {
    dispatch(createForo())
  }
  
  return (
    <div className='section1 container-fluid'>
        <Row className='align-items-center'>
            <Col>
                <h1 className='text-dark-50 ms-1 text-info'>Foro</h1>
            </Col>
            <Col className='text-right'>
                <Button className='my-3 btn-info' onClick={createForoHandler}>
                    <i className='fas fa-plus'></i> Crear Foro
                </Button>
            </Col>            
        </Row>
        {loadingDelete && <Loader />}
        {errorDelete && <Message variant='danger'>{errorDelete}</Message>}

        {loadingCreate && <Loader />}
        {errorCreate && <Message variant='danger'>{errorCreate}</Message>}        

        {loading ? 
          (<Loader />)
        : error ?
          (<Message variant='danger'>{error}</Message>)
        : (
          <div>
            <Table bordered hover responsive className='table-lg text-dark fs-5'>
              <thead className="table-info">
              <tr>
                <th>ID</th>
                <th>NOMBRE</th>
                <th>AUTOR</th>
                <th>FECHA</th>
                <th>COMENTARIOS</th>
                <th></th>
                <th></th>                            
              </tr>
            </thead>
            <tbody>
            {foros.length === 0 ? <p className='text-danger fx-2'><b>No existen Foros actualmente</b></p> : ''}
              {foros.map(foro => (
                <tr key={foro._id}>
                  <td>{foro._id}</td>
                  <td>{foro.name}</td>
                  <td>{foro.author}</td>
                  <td><Moment format="DD/MM/YYYY hh:mm:ss">{foro.createdAt}</Moment></td>
                  <td>{foro.comment}</td>                  
                  <td>
                    <LinkContainer to={`/admin/foro/${foro._id}/edit`}>
                      <Button variant='light' className='btn-sm'>
                        <i className='fas fa-edit'></i>
                      </Button>
                    </LinkContainer>
                  </td>
                  <td>  
                    <Button variant='danger' className='btn-sm' onClick={() => deleteHandler(foro._id)}>
                      <i className='fas fa-trash'></i>
                    </Button>                    
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          </div>
        )  
      }
    </div>
  )
}

export default ForoListScreen