import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Button, Card, Form } from 'react-bootstrap';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Loader from '../components/Loader'
import Message from '../components/Message'
import { listEducations } from '../actions/educationActions'
import { listMoocs } from '../actions/moocActions'
import { listBadges } from '../actions/badgeActions'
import image1 from '../assets/images/education-page/header.svg'
import Education from '../components/Education';
import Mooc from '../components/Mooc'
import BadgeC from '../components/BadgeC';

function EducationScreen() {

  const dispatch = useDispatch()

  let navigate = useNavigate();
  let keyword = useLocation().search

  const educationList = useSelector(state => state.educationList)
  const { error, loading, educations } = educationList

  const moocList = useSelector(state => state.moocList)
  const { moocs } = moocList

  const badgeList = useSelector(state => state.badgeList)
  const { badges } = badgeList


  useEffect(() => {
    dispatch(listEducations(keyword))
    dispatch(listMoocs(keyword))
    dispatch(listBadges(keyword))

     window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
     
  }, [dispatch, keyword])
  
  return (

    <div>
    <Link to='/' className='btn btn-light mt-2'>Go Back</Link>
    <section>
        <div class="container-fluid px-3 pt-2">
            <div class="row text-dark mx-auto">
                <div class="col-md-6"> <img class="img-fluid d-block  animate__animated animate__zoomIn animate__delay-1s"
                    src={image1} draggable="true" /> 
                </div>
                <div class="px-md-5 p-3 col-md-6 d-flex flex-column align-items-center justify-content-center animate__animated animate__zoomIn animate__delay-1s">
                    <h1 class="main-heading text-info">Educación</h1>
                    <p class=" pre-heading font-weight-bolder text-center animate__animated animate__fadeInUp animate__delay-2s h5 my-2">
                    Invertir en conocimiento paga el mejor interes. <br /> <br /> <span className='text-info fs-6'>- Benjamin Franklin.</span>
                    </p>
                </div>
            </div>
        </div>
    </section>

  <section>
    <div class="container-fluid px-5">
      <h2 class=" text-center text-info py-4 animate__animated animate__zoomIn animate__delay-1s formal-edu my-3">Educación Formal</h2>
        <div class="row">
          <div class="col-md-12" data-aos="fade-up" data-aos-easing="linear" data-aos-delay="300">
            <div class="main-timeline timeline">
              {
                loading ? <Loader /> 
                : error ? <Message variant='danger'>{error}</Message>
                  : <div>
                    {educations.length === 0 ? <h4><b>No existe Educación actualmente</b></h4> : ''}
                      {educations.map(education => (
                            <Education education={education} />
                    ))}
                  </div>
                }
            </div>
          </div>
        </div>
      </div>
   </section>
   <hr className='text-info'/>
<section>
  <div class="container-fluid">
    <h2 class="text-center text-info pb-2 pt-5" data-aos="fade-up" data-aos-delay="300">MOOCs</h2>
    <p className='text-bold text-success fs-4'>Los Cursos Masivos Abiertos en Línea (MOOCs) son cursos en línea gratuitos disponibles para que cualquiera se inscriba. Los MOOC proporcionan una forma asequible y flexible de aprender nuevas habilidades, avanzar en su carrera y ofrecer experiencias educativas de calidad a escala.</p>
    <div class="row moocs">
    {
      loading ? <Loader /> 
      : error ? <Message variant='danger'>{error}</Message>
        : <div>
        <Row className='mx-1'>
          {moocs.length === 0 ? <h4><b>No existe Moocs actualmente</b></h4> : ''}
            {moocs.map(mooc => (
              <Col key={mooc._id} sm={3}>
                  <Mooc mooc={mooc} />
              </Col>                        
          ))}
        </Row>
        </div>
      }
    </div>
  </div>
</section>
<hr className='text-info'/>
<section>
  <div class="py-2 text-center">
    <div class="container-fluid">
      <div class="row">
        <div class="mx-auto col-md-12">
          <h2 class="text-center text-info pb-1 pt-3" data-aos="fade-up" data-aos-delay="300">Distintivos</h2>
        </div>
      </div>
      {
        loading ? <Loader /> 
        : error ? <Message variant='danger'>{error}</Message>
          : <div class="row justify-content-center badges">
            {badges.length === 0 ? <h4><b>No existe Distintivos actualmente</b></h4> : ''}
              {badges.map(badge => (
                <Col key={badge._id} sm={4}>
                    <BadgeC badge={badge} />
                </Col>                        
            ))}
          </div>
        }
    </div>
  </div>
  </section>
 
  </div>


  )
}

export default EducationScreen