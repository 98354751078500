import React, { useEffect, useState } from 'react';
import { Row, Col, Image, Card } from 'react-bootstrap';
import Moment from 'react-moment';
import { Link } from 'react-router-dom'
import ScrollAnimation from 'react-animate-on-scroll';

function Project({ project }) {

  useEffect(() => {

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    
}, [])

const url = project.link

  return (
    <div class="mt-2">
    <ScrollAnimation animateIn="fadeIn duration='2s' offset">
    <Card className='mb-3 border border-info border-3 rounded h-100 imageprofile animate__animated animate__fadeInUp animate__delay-1s card'>
        <div class='row'>
          <div class='col-md-5'>
              <Card.Img src={project.image} className="card-img-top my-2 img-fluid " alt="Imagen" style={{height: '55vh', maxHeight: '55vh'}}/>
          </div>
          <div class='col-md-7'>
            <Card.Header className='bg-info'>
              <h3 className='text-center text-light'>{project.name}</h3>
            </Card.Header>
                  <Card.Body>
                     <Card.Text className='text-center h6'><a href={project.link} target='blank' className='text-decoration-none text-success'>Link al sitio</a></Card.Text>
                     <ul>
                        {project.backend ? <li><b>Backend:</b> {project.backend}</li> : ''}
                        {project.frontend ? <li><b>Frontend:</b> {project.frontend}</li> : ''}
                        {project.database ? <li><b>Base Datos:</b> {project.database}</li> : ''}
                        {project.deploy ? <li><b>Producción:</b> {project.deploy}</li> : ''}
                        {project.platform ? <li><b>Plataforma:</b> {project.platform}</li> : ''}                                                                                                
                     </ul>
                     <Card.Text className='text-center h6'><strong>Período:</strong> {project.timeline}</Card.Text>                     
                  </Card.Body>
                <Card.Footer as='div'>
                <div class="d-flex flex-row bd-highlight justify-content-center align-items-center">
                  <p className='fs-5 text-info fw-bolder mt-2'><b>{project.comment}</b></p>
                </div>
                </Card.Footer>
            </div>
       </div>
    </Card>
    </ScrollAnimation>
    </div>
  )
}

export default Project