import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Button, Card, Form } from 'react-bootstrap';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Loader from '../components/Loader'
import Message from '../components/Message'
import { listProjects } from '../actions/projectActions'
import image1 from '../assets/images/experience-page/cursos.jpg'
import image2 from '../assets/images/freelance-work.png'
import image3 from '../assets/images/html.png'
import image4 from '../assets/images/css.png'
import image5 from '../assets/images/js.png'
import image6 from '../assets/images/react.png'
import image7 from '../assets/images/aws.png'
//import Project from '../components/Project';
import image8 from '../assets/images/work_optimized.png'
import ScrollAnimation from 'react-animate-on-scroll';


function SoftIntegralScreen() {

  const dispatch = useDispatch()

  let navigate = useNavigate();
  let keyword = useLocation().search

  const projectList = useSelector(state => state.projectList)
  const { error, loading, projects } = projectList


  useEffect(() => {
    dispatch(listProjects(keyword))

     window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
     
  }, [dispatch, keyword])

  const temario = () => {
    alert('Contáctame para enviarte el temario. Gracias por el interes.')
  }
  
  return (

    <div>
    <Link to='/' className='btn btn-light mt-2'>Go Back</Link>

    <section >
    <div class="row text-dark">
    <div
      class="col-md-6 d-flex mt-5 flex-column text-center justify-content-center animate__animated animate__zoomIn animate__delay-1s">
      <h1 class="main-heading mb-0 text-center text-info mb-1">SKYLER</h1>
      <p class="pre-heading font-weight-bolder mb-0 mt-3 text-center animate__animated animate__zoomIn animate__delay-1s h5" >
            El software integral que controla tu negocio<br />
            <br />
            Los <span className='text-success fw-bold'>datos</span> no solo son la materia prima para la toma de decisiones informadas, sino que también representan un activo estratégico que impulsa la innovación, mejora la eficiencia operativa y fortalece la relación con los clientes.<br /> <br /><span className='text-info fs-6'></span>
      </p>
    </div>
    <div class="col-md-6 d-flex justify-content-center align-items-center">
      <img class="img-header img-fluid d-block img-fluid animate__animated animate__zoomIn animate__delay-1s" src={image8} />
    </div>
  </div>
    </section>

    <section>

      <Row className='mt-5'>
        <Col md={12}>
          <h1 class="text-center exp-head heading1 my-4 animate__animated animate__zoomIn animate__delay-1s formal-edu text-info main-heading">SKYLER... El software que te permite de forma fácil llevar y controlar tu negocio</h1>      
          <p className='fs-3 text-dark text-center fw-bold animate__animated animate__zoomIn animate__delay-1s'>¿No tienes control de tus ventas. Sabes cuanto gastas en tus compras? Te gustaría vender en línea, pero no te animas porque no sabes por donde empezar, no tienes presencia en Internet?</p>
        </Col>
      </Row>
      <Row className='d-flex justify-content-center align-items-center'>
        <Col md={10}>
          <p className='fs-3 text-dark text-center fw-bold animate__animated animate__zoomIn animate__delay-1s'>¿Sabías que en México hay alrededor de 5 millones de empresas, pero solo 10% tiene presencia en línea?</p>
          <p className='fs-5 fw-bold text-dark text-center animate__animated animate__zoomIn animate__delay-1s'>
          En <span className='fw-bold fs-5 text-info'>Skyler</span> podrás obtener tu sitio corporativo, comercio electrónico, intranet corporativa, ventas por remisión y por punto de venta, compras, clientes, proveedores, facturación cfdi 4.0, reportes e inteligencia de negocio, todo en la nube, con disponibilidad 24/7 los 365 dias del año, para todo tipo de dispositivos, computadoras, laptops, moviles, ipads, etc.
          </p>
        </Col>
      </Row>
      <hr className='text-info'/>
      <Row className='mt-2'>
        <Col md={4}>
          <p className='fs-5 text-dark text-center fw-bold'>La calidad de los datos es un aspecto crítico en cualquier empresa, ya que su mala gestión puede impactar negativamente, además su mala calidad puede generar pérdida de confianza de los consumidores, costos innecesarios y hasta problemas legales.</p>
          <p className='fs-3 text-center text-danger fw-bold mt-4'>Usted paga por lo que necesita!</p>
          <p className='fs-4 text-center text-info fw-bold mt-4'>Skyler se adapta 100% a sus necesidades!</p>
        </Col>
        <Col md={4}>
          <p className='fs-3 text-dark text-center fw-bolder'>¿Te gustaría una demostración?</p>
          <p className='fs-4 text-center text-info fw-bolder'>Con la posibilidad de ingresar al sitio desde cualquier dispositivo, computadoras, laptops y hasta celulares!</p>          
          <p className='fs-4 text-center text-danger fw-bold'><mark>Skyler se adapta completamente a sus necesidades.</mark></p>          
        </Col>
        <Col md={4}>
          <p className='fs-5 text-danger text-center fw-bold'>!Existen en el mercado muchas aplicaciones de este tipo en las cuales usted o su empresa tienen que adaptarse, con Skyler usted define lo que necesita, los módulos que requiere, y de esta forma se crea un traje a la medida de su empresa!</p>
          <p className='fs-5 text-center text-success fw-bold'><mark>Nada de Frameworks como WordPress, Hostinger o otros. Skyler permite flexibilidad y se adapta a sus necesidades. </mark></p>

        </Col>
      </Row>
      <Row>
      <Col md={12}>
        <p className='fs-3 text-center text-success fw-bold'><mark>Con la experiencia de muchos años como Lider de Proyecto de Aspel, SAP B1 y SAP Hana.</mark></p>
      </Col>
    </Row>
      <Row>
        <Col md={12}>
        <p className='fs-3 text-center text-success fw-bold'><strong>Necesita los módulos de ventas y facturación, listo, necesita presencia en Internet con sitio web y comercio electrónico, listo, necesita inteligencia de negocios ligado a su sistema, listo, lo que su empresa necesite.</strong></p>

        </Col>
      </Row>
      <hr className='text-info'/>
    </section>

    <section className='my-4'>
      <ScrollAnimation animateIn="fadeIn duration='2s' offset">
      <Row>
        <Col md={12}>
          <p className='fw-bolder display-5 text-center'>Encuentre el mejor <strong>paquete <span className='text-info'>SKYLER</span></strong> que mejor se adapte a las necesidades de su compañia</p>
          <p className='fw-bolder display-6 text-center text-info'>Paquetes de diseño y desarrollo web disponibles</p>
        </Col>
      </Row>
        <div class='row'>
          <div class='col-md-6'>
          <Card className='mb-3 border border-info border-3 rounded h-100 imageprofile animate__animated animate__fadeInUp animate__delay-1s card'>
            <Card.Header className='bg-info'>
              <p className='text-center fs-4 text-light mt-2'>Skyler Básica</p>
            </Card.Header>
                  <Card.Body>
                     <Card.Text className='h5' style={{textAlign: 'justify'}}>Nuestra página web básica está diseñada para aquellos que necesitan una presencia en línea esencial. Esta solución incluye varios métodos de contacto para asegurar que tus clientes puedan encontrarte fácilmente, así como un resumen de tus productos y servicios.</Card.Text>
                     <Card.Text className='text-center h6 fw-bold'><strong>Período de Desarrollo:</strong>5 semanas</Card.Text>                    
                  </Card.Body>
                <Card.Footer as='div' className='bg-info'>
                <div class="d-flex flex-row bd-highlight justify-content-center align-items-center">
                </div>
                  <p className='fs-5 text-light mt-2 text-center'>Si requieres saber más de este paquete, <Link to='/contact'>contactáctame</Link>, de inmediato le enviaré la información.</p>
                </Card.Footer>
                </Card>
            </div>

       <div class='col-md-6'>
       <Card className='mb-3 border border-info border-3 rounded h-100 imageprofile animate__animated animate__fadeInUp animate__delay-1s card'>
         <Card.Header className='bg-info'>
          <p className='text-center fs-4 text-light mt-2'>Skyler Profesional</p>
         </Card.Header>
               <Card.Body>
                  <Card.Text className='h5 mt-2' style={{textAlign: 'justify'}}>Nuestra pagina web profesional esta creada para empresas experimentadas en crecimiento y desarrollo que desean proyectar una imagen profesional, nuestro paquete de páginas web profesionales ofrece mayor capacidad para destacar y detallar sus servicios y productos.</Card.Text>
                  <Card.Text className='text-center h6 mt-2'><strong>Período de Desarollo:</strong>5 semanas</Card.Text>                     
               </Card.Body>
             <Card.Footer as='div' className='bg-info'>
             <div class="d-flex flex-row bd-highlight justify-content-center align-items-center">
             <p className='fs-5 text-light mt-2 text-center'>Si requieres saber más de este paquete, <Link to='/contact'>contactáctame</Link>, de inmediato le enviaré la información.</p>
             </div>
             </Card.Footer>
             </Card>
         </div>

      </div>
      <div class='row my-3'>
      <div class='col-md-6'>
      <Card className='mb-3 border border-info border-3 rounded h-100 imageprofile animate__animated animate__fadeInUp animate__delay-1s card'>
        <Card.Header className='bg-info'>
         <p className='text-center fs-4 text-light mt-2'>Skyler E-Commerce</p>
        </Card.Header>
              <Card.Body>
                  <Card.Text className='h5 mt-2' style={{textAlign: 'justify'}}>El catalogo de productos o Ecommerce está diseñado para empresas que tienen catálogos de más de 50 productos y quieren mostrar mas carateristicas o imagenes a detalle de los mismos, a si como manejar inventarios, generar pedidos e inclusive ventas en línea.</Card.Text>
                 <Card.Text className='text-center h6 mt-2'><strong>Período de Desarollo:</strong>5 semanas</Card.Text>                     
              </Card.Body>
            <Card.Footer as='div' className='bg-info'>
            <div class="d-flex flex-row bd-highlight justify-content-center align-items-center">
            <p className='fs-5 text-light mt-2 text-center'>Si requieres saber más de este paquete, <Link to='/contact'>contactáctame</Link>, de inmediato le enviaré la información.</p>
            </div>
            </Card.Footer>
            </Card>
        </div>
        <div class='col-md-6'>
        <Card className='mb-3 border border-info border-3 rounded h-100 imageprofile animate__animated animate__fadeInUp animate__delay-1s card'>
          <Card.Header className='bg-info'>
           <p className='text-center fs-4 text-light mt-2'>Skyler Corporativa</p>
          </Card.Header>
                <Card.Body>
                    <Card.Text className='h5 mt-2' style={{textAlign: 'justify'}}>La pagina Web corporativa o empresarial, es nuestro paquete más completo, incluye la última tecnología en código de programación, manejo y optimización de imágenes, diseños de alto impacto, navegacion fluida, así como optimización para buscadores seo.</Card.Text>
                   <Card.Text className='text-center h6 mt-2'><strong>Período:</strong>5 semanas</Card.Text>                     
                </Card.Body>
              <Card.Footer as='div' className='bg-info'>
              <div class="d-flex flex-row bd-highlight justify-content-center align-items-center">
              <p className='fs-5 text-light mt-2 text-center'>Si requieres saber más de este paquete, <Link to='/contact'>contactáctame</Link>, de inmediato le enviaré la información.</p>
              </div>
              </Card.Footer>
              </Card>
          </div>
      
      </div>

    </ScrollAnimation>
    </section>
    <section className='my-4'>
    <ScrollAnimation animateIn="fadeIn duration='2s' offset">
      <p className='fs-4 text-dark mt-2 text-center mt-5'>Si necesita una demostración de algun paquete SKYLER, <Link to='/contact'>contactáctame</Link>, y agendámos una cita.</p>
    </ScrollAnimation>    
    
    
   </section>
  </div>


  )
}

export default SoftIntegralScreen