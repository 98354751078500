export const BADGE_LIST_REQUEST = 'BADGE_LIST_REQUEST'
export const BADGE_LIST_SUCCESS = 'BADGE_LIST_SUCCESS'
export const BADGE_LIST_FAIL = 'BADGE_LIST_FAIL'

export const BADGE_DETAILS_REQUEST = 'BADGE_DETAILS_REQUEST'
export const BADGE_DETAILS_SUCCESS = 'BADGE_DETAILS_SUCCESS'
export const BADGE_DETAILS_FAIL = 'BADGE_DETAILS_FAIL'

export const BADGE_DELETE_REQUEST = 'BADGE_DELETE_REQUEST'
export const BADGE_DELETE_SUCCESS = 'BADGE_DELETE_SUCCESS'
export const BADGE_DELETE_FAIL = 'BADGE_DELETE_FAIL'

export const BADGE_CREATE_REQUEST = 'BADGE_CREATE_REQUEST'
export const BADGE_CREATE_SUCCESS = 'BADGE_CREATE_SUCCESS'
export const BADGE_CREATE_FAIL = 'BADGE_CREATE_FAIL'
export const BADGE_CREATE_RESET = 'BADGE_CREATE_RESET'

export const BADGE_UPDATE_REQUEST = 'BADGE_UPDATE_REQUEST'
export const BADGE_UPDATE_SUCCESS = 'BADGE_UPDATE_SUCCESS'
export const BADGE_UPDATE_FAIL = 'BADGE_UPDATE_FAIL'
export const BADGE_UPDATE_RESET = 'BADGE_UPDATE_RESET'