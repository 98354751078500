import React, { useEffect } from 'react'
import { Navbar, Nav, NavDropdown} from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import logo from '../assets/images/logoEVP-1.svg'
import { logout } from '../actions/userActions'
import { listContacts } from '../actions/contactActions'

function Header() {

  const dispatch = useDispatch()
  const navigate = useNavigate();

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const contactList = useSelector(state => state.contactList)
  const { contacts } = contactList

  const logoutHandler = () => {
    dispatch(logout())
  }

  useEffect(() => {
    
      dispatch(listContacts())
    
  }, [dispatch, navigate, userInfo])

  return (
    <header>
    <Navbar bg="light" expand="lg" collapseOnSelect>
      <a href="/" class="navbar-brand ms-2" ><img src={logo} alt='Logo MC' resizeMode='contain' className='img-fluid'/></a>
      <Navbar.Toggle aria-controls="basic-navbar-nav" className='me-1'/>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto me-3">
          <LinkContainer to='/'>
            <Nav.Link className='active'><span className='colornav fs-5'>INICIO</span></Nav.Link>                
          </LinkContainer>
          <LinkContainer to='/about'>
            <Nav.Link><span className='colornav fs-5'>ACERCA</span></Nav.Link>                
          </LinkContainer>
          <LinkContainer to='/contact'>
            <Nav.Link><span className='colornav fs-5'>CONTACTO</span></Nav.Link>                
          </LinkContainer>
          <LinkContainer to='/sintegral'>
          <Nav.Link><span className='colornav fs-5'>SKYLER</span></Nav.Link>                
        </LinkContainer>
          <NavDropdown title={ <span className="colornav fs-5">TEMAS</span> } id="nav-dropdown">
            <LinkContainer to='/exp'>
              <Nav.Link><span className='colornav fs-5'>EXPERIENCIA</span></Nav.Link>                
            </LinkContainer>
            <LinkContainer to='/project'>
              <Nav.Link><span className='colornav fs-5'>PROYECTOS</span></Nav.Link>                
            </LinkContainer>
            <LinkContainer to='/course'>
              <Nav.Link><span className='colornav fs-5'>CURSOS</span></Nav.Link>                
            </LinkContainer>
            <LinkContainer to='/blog'>
              <Nav.Link><span className='colornav fs-5'>BLOG</span></Nav.Link>                
            </LinkContainer>
            <LinkContainer to='/foro'>
              <Nav.Link><span className='colornav fs-5'>FORO</span></Nav.Link>
            </LinkContainer>
            <LinkContainer to='/education'>
              <Nav.Link><span className='colornav fs-5'>EDUCACION</span></Nav.Link>                
            </LinkContainer>
            <LinkContainer to='/service'>
              <Nav.Link><span className='colornav fs-5'>SERVICIOS</span></Nav.Link>                
            </LinkContainer>
            <Nav.Link href="https://docs.google.com/document/d/e/2PACX-1vT5f5woGhYoWfq3GiwOx_RMUtiqB2cUP46NsiheSEK0325zgdMXLAKD7kWbl9GLNZ8TUMCdbBOamcA6/pub" target="_blank"><span className='colornav fs-5'>MI RESUME</span></Nav.Link>
          </NavDropdown>
          {userInfo ? (
            <NavDropdown title={<span className='colornav fs-5'>{userInfo.name}</span>} id='username'>
                <LinkContainer to={'/profileuser'}>
                    <Nav.Link><span className="colornav fs-5">PROFILE</span></Nav.Link>
                </LinkContainer>
                    <Nav.Link onClick={logoutHandler}><span className=" colornav fs-5">LOGOUT</span></Nav.Link>                          
                </NavDropdown>
            ) : (
            <LinkContainer to='/login'>
              <Nav.Link><span className="colornav fs-5">LOGIN</span></Nav.Link>
            </LinkContainer>
            )
            }
            {userInfo && userInfo.isAdmin && (
            <NavDropdown title={<span className="colornav">Admin</span>} id='adminmenu'>
              <LinkContainer to='/admin/contactlist' className='menubarfont'>
                <Nav.Link className='colorMenu'>
                  <i class="fa fa-fw fa-user text-dark mr-3"></i>
                  <b><span class="position-absolute top-10 left-100 translate-middle badge rounded-pill bg-light text-dark">{contacts.length > 0 ? contacts.length : ''}</span></b>                        
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to='/admin/articlelist' className='colorMenu'>
                <Nav.Link><span className="colornav">Blogs</span></Nav.Link>
              </LinkContainer>
              <LinkContainer to='/admin/forolist' className='colorMenu'>
                <Nav.Link><span className="colornav">Foros</span></Nav.Link>
              </LinkContainer>
              <LinkContainer to={'/admin/userlist'}>
                  <Nav.Link><span className="colornav">Usuarios</span></Nav.Link>
              </LinkContainer>
            </NavDropdown>                    
            )}

        </Nav>
      </Navbar.Collapse>

  </Navbar>
    </header>
  )
}

export default Header
