import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Button, Card, Form } from 'react-bootstrap';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { listArticles } from '../actions/articleActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import image1 from '../assets/images/research-page/research1.svg'
import Article from '../components/Article';

function BlogScreen() {

  const dispatch = useDispatch()

  let navigate = useNavigate();
  let keyword = useLocation().search

  const articleList = useSelector(state => state.articleList)
  const {error, loading, articles} = articleList

  useEffect(() => {
    dispatch(listArticles(keyword))

     window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
     
  }, [dispatch, keyword])
  
  return (

    <div>
    <Link to='/' className='btn btn-light mt-2'>Go Back</Link>
    <section>
      <div class="px-3 pt-5">
        <div class="row">
          <div
            class="col-md-6 d-flex flex-column text-center justify-content-center animate__animated animate__zoomIn animate__delay-1s">
            <h1 class="main-heading mb-0 head-upper text-info">Blog</h1>
            <p class="pre-heading p-upper font-weight-bolder mb-0 mt-3 text-center animate__animated animate__zoomIn animate__delay-2s fs-5 fw-bold"
              >
              <b>Blogs son articulos informales escritos con el proposito de mostrar liderazgo de pensamiento y experiencia en un tema
              en particular. Son una excelente forma de generar contenido fresco en un sitio web y proveer un catalizador para marketing 
              por correo electrónico, promoción de redes sociales para impulsar el tráfico de búsqueda al sitio web, 
              Estos temas podrán ser ideas, opiniones, información personal o profesional, experiencias, etc.</b>
            </p>
          </div>

          <div class="col-md-6">
            <img class="img-fluid d-block img-fluid d-block animate__animated animate__zoomIn animate__delay-1s" src={image1} />
          </div>
        </div>
      </div>
    </section>


    <h2 class="text-center text-info exp-head heading1 my-5 animate__animated animate__zoomIn animate__delay-1s formal-edu mt-5">Blogs</h2>

    {
      loading ? <Loader /> 
      : error ? <Message variant='danger'>{error}</Message>
        : <div>
        <Row className='mx-1'>
          {articles.length === 0 ? <h4><b>No existen Blogs actualmente</b></h4> : ''}
          <p className='text-bold mb-4 fs-5 text-danger text-center animate__animated animate__zoomIn animate__delay-1s'>Click en el tema</p>
            {articles.map(article => (
              <Col key={article._id} md={12}>
                  <Article article={article} />
              </Col>                        
          ))}
        </Row>
        </div>
  }

  </div>


  )
}

export default BlogScreen