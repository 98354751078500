export const MOOC_LIST_REQUEST = 'MOOC_LIST_REQUEST'
export const MOOC_LIST_SUCCESS = 'MOOC_LIST_SUCCESS'
export const MOOC_LIST_FAIL = 'MOOC_LIST_FAIL'

export const MOOC_DETAILS_REQUEST = 'MOOC_DETAILS_REQUEST'
export const MOOC_DETAILS_SUCCESS = 'MOOC_DETAILS_SUCCESS'
export const MOOC_DETAILS_FAIL = 'MOOC_DETAILS_FAIL'

export const MOOC_DELETE_REQUEST = 'MOOC_DELETE_REQUEST'
export const MOOC_DELETE_SUCCESS = 'MOOC_DELETE_SUCCESS'
export const MOOC_DELETE_FAIL = 'MOOC_DELETE_FAIL'

export const MOOC_CREATE_REQUEST = 'MOOC_CREATE_REQUEST'
export const MOOC_CREATE_SUCCESS = 'MOOC_CREATE_SUCCESS'
export const MOOC_CREATE_FAIL = 'MOOC_CREATE_FAIL'
export const MOOC_CREATE_RESET = 'MOOC_CREATE_RESET'

export const MOOC_UPDATE_REQUEST = 'MOOC_UPDATE_REQUEST'
export const MOOC_UPDATE_SUCCESS = 'MOOC_UPDATE_SUCCESS'
export const MOOC_UPDATE_FAIL = 'MOOC_UPDATE_FAIL'
export const MOOC_UPDATE_RESET = 'MOOC_UPDATE_RESET'