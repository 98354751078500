import React, { useEffect } from 'react';
import { Card, Image, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Moment from 'react-moment';
import ScrollAnimation from 'react-animate-on-scroll';


function Article({ article }) {

    const dispatch = useDispatch()

    let keyword = useLocation().search
  
    useEffect(() => {
  
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      
  }, [dispatch, keyword])
  return (
    <ScrollAnimation animateIn="fadeIn duration='2s' offset">
    <Card className='mb-3 p-3 rounded card-img-top colorcrd border border-info border-3 d-flex justify-content-center align-items-center' >
        <Card.Header className='bg-info mt-2'>
        <Link to={`/article/${article._id}`} className='text-decoration-none'>
            <h2 className='text-center text-light'><strong>{article.name}</strong></h2>
        </Link>            
        </Card.Header>
        <Card.Body>
            <h3><strong>{article.title}</strong></h3>
            <h5><strong>{article.subtitle}</strong></h5>
            <h6 className='text-success'><strong>{article.createby}</strong></h6>
            <h6 className='text-success'><strong>{article.timeline}</strong></h6>
        </Card.Body>
        <Card.Footer className='bg-info mt-2'>
        <Row>
            <Col md={12}>
                <p className='fs-6 fw-bold text-light d-flex justify-content-center align-items-center'>Subido por: {article.author}</p>
            </Col>
            <Col md={12}>
                <p className='fs-6 fw-bold text-light d-flex justify-content-center align-items-center'><Moment format="DD/MM/YYYY hh:mm:ss">{article.createdAt}</Moment></p>
            </Col>
        </Row>
        </Card.Footer>
    </Card>
    </ScrollAnimation>   
  )
}

export default Article