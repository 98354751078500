import { 
    MOOC_LIST_REQUEST, 
    MOOC_LIST_SUCCESS, 
    MOOC_LIST_FAIL,
    MOOC_DETAILS_REQUEST,
    MOOC_DETAILS_SUCCESS,
    MOOC_DETAILS_FAIL,
    MOOC_DELETE_REQUEST, 
    MOOC_DELETE_SUCCESS, 
    MOOC_DELETE_FAIL,

    MOOC_CREATE_REQUEST, 
    MOOC_CREATE_SUCCESS, 
    MOOC_CREATE_FAIL,
    MOOC_CREATE_RESET,
    
    MOOC_UPDATE_REQUEST, 
    MOOC_UPDATE_SUCCESS, 
    MOOC_UPDATE_FAIL,
    MOOC_UPDATE_RESET,

} from '../constants/moocConstants'

export const moocListReducer = (state = {moocs: []}, action) => {
    switch(action.type){
        case MOOC_LIST_REQUEST:
            return {loading: true, moocs:[]}
        case MOOC_LIST_SUCCESS:
            return {loading: false, moocs: action.payload}
        case MOOC_LIST_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state    
    }
}

export const moocDetailsReducer = (state = {mooc: {reviews:[]}}, action) => {
    switch(action.type){
        case MOOC_DETAILS_REQUEST:
            return { loading: true, ...state }
        case MOOC_DETAILS_SUCCESS:
            return {loading: false, mooc: action.payload}
        case MOOC_DETAILS_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state    
    }
}

export const moocCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case MOOC_CREATE_REQUEST:
            return { loading: true }
    
        case MOOC_CREATE_SUCCESS:
            return { loading: false, success: true, mooc: action.payload }
    
        case MOOC_CREATE_FAIL:
            return { loading: false, error: action.payload }
    
        case MOOC_CREATE_RESET:
                return {}            
    
        default:
            return state
    }
    }
    
    
    export const moocDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case MOOC_DELETE_REQUEST:
            return { loading: true }
    
        case MOOC_DELETE_SUCCESS:
            return { loading: false, success: true }
    
        case MOOC_DELETE_FAIL:
            return { loading: false, error: action.payload }
    
        default:
            return state
    }
    }
    
    
    export const moocUpdateReducer = (state = {mooc: {}}, action) => {
    switch (action.type) {
        case MOOC_UPDATE_REQUEST:
            return { loading: true }
    
        case MOOC_UPDATE_SUCCESS:
            return { loading: false, success: true, mooc: action.payload }
    
        case MOOC_UPDATE_FAIL:
            return { loading: false, error: action.payload }
    
        case MOOC_UPDATE_RESET:
                return { mooc: {} }            
    
        default:
            return state
        }
    }