import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
//import logo from '../assets/img/ecofertasLogo1.png'

function Footer() {


  return (
    <footer class="colorfooter" id="tempaltemo_footer">
        <div class="container colorfooter">
            <div class="row">

                   <div class="col-md-4 pt-5">
                    <a class="navbar-brand text-success logo h1 align-self-center" href="index.html">
                        {/*<img src={logo}  style={{ width: 170, height: 99 }} resizeMode='contain' alt='fotoP' />*/}
                    </a>
                    <ul class="list-unstyled text-light footer-link-list">
                        <li>
                            Eduardo Villalpando Prieto
                        </li>
                        <li className='text-light'>
                        <i class="fas fa-map-marker-alt fa-fw"></i>
                            Fresno 35, Bosques de San Juan, <br /> 
                            San Juan del Río, Querétaro.
                        </li>
                        <li>
                            <i class="fa fa-phone fa-fw"></i>
                            <a class="text-decoration-none text-light" href="tel:55-17923352">55-78581483</a>
                        </li>
                        <li>
                            <i class="fa fa-envelope fa-fw"></i>
                            <a class="text-decoration-none text-light" href="mailto:info@company.com">villalpe5@gmail.com</a>
                        </li>
                    </ul>
                </div>

                <div class="col-md-4 pt-5">
                    <h2 class="h2 text-light border-bottom pb-3 border-light">Secciones</h2>
                    <ul class="collapse show list-unstyled pl-3 ">
                        <li><a class="text-decoration-none text-light" href='/#/course'>Cursos</a></li>
                        <li><a class="text-decoration-none text-light" href='/#/expirience'>Experiencias</a></li>
                        <li><a class="text-decoration-none text-light" href='/#/project'>Proyectos</a></li>
                        <li><a class="text-decoration-none text-light" href='/#/blog'>Blogs</a></li>
                        <li><a class="text-decoration-none text-light" href='/#/education'>Educación</a></li>
                        <li><a class="text-decoration-none text-light" href='/#/service'>Servicios</a></li>
                        <li><a class="text-decoration-none text-light" href='/#/foro'>Foros</a></li>
                    </ul>
                </div>

                <div class="col-md-4 pt-5">
                    <h2 class="h2 text-light border-bottom pb-3 border-light">Mas información</h2>
                    <ul class="list-unstyled text-light footer-link-list">
                        <li><a class="text-decoration-none text-light" href="#">Inicio</a></li>
                        <li><a class="text-decoration-none text-light" href='/#/about'>Acerca de mí</a></li>
                        <li><a class="text-decoration-none text-light" href="#">Preguntas más comunes</a></li>
                        <li><a class="text-decoration-none text-light" href='/#/contact'>Contacto</a></li>
                    </ul>
                </div>

            </div>

            <div class="row text-light mb-4">
                <div class="col-12 mb-3">
                    <div class="w-100 my-3 border-top border-light"></div>
                </div>
                <div class="col-auto me-auto">
                    <ul class="list-inline text-left footer-icons">
                        <li class="list-inline-item border border-light rounded-circle text-center fs-5">
                            <a class="social-icon facebook text-light" href="https://facebook.com/eduardo.villalpando.14019338" target="_blank" rel="author">
                                <i class="fab fa-facebook-f fa-md fa-fw"></i>
                            </a>
                        </li>
                        <li class="list-inline-item border border-light rounded-circle text-center fs-5">
                            <a class="social-icon twitter text-light" href="https://twitter.com/Eduardo49353622" target="_blank" rel="author" >
                                <i class="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li class="list-inline-item border border-light rounded-circle text-center fs-5">
                            <a class="social-icon linkedin text-light" href="https://www.linkedin.com/in/eduardo-villalpando-9b7494130/" target="_blank" rel="author">
                                <i class="fab fa-linkedin-in"></i>
                            </a>
                        </li>
                        <li class="list-inline-item border border-light rounded-circle text-center fs-5">
                            <a class="social-icon dribbble text-light" href="https://dribbble.com/villalpe5" target="_blank" rel="author">
                                <i class="fab fa-dribbble"></i>
                            </a>
                        </li>
                        <li class="list-inline-item border border-light rounded-circle text-center fs-5">
                            <a class="social-icon medium text-light" href="https://medium.com/@villalpe5" target="_blank" rel="author">
                                <i class="fab fa-medium-m"></i>
                            </a>
                        </li>                        
                    </ul>
                </div>
                <div class="col-auto">
                    <label class="sr-only" for="subscribeEmail">Dirección de Correo</label>
                    <div class="input-group mb-2">
                        <input type="text" class="form-control bg-dark border-light text-light" id="subscribeEmail" placeholder="Email address" />                    
                        <div class="input-group-text btn-success text-info">Suscribirte</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="w-100 bg-black py-3">
            <div class="container">
                <div class="row pt-2">
                    <div class="col-12">
                        <p class="text-left">
                            Copyright &copy; 2022 EVP 
                            | Designed by <a rel="sponsored" href="https://pofoevp63.com" class='text-light'>WEV Solutions</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>

    </footer>
  )
}

export default Footer