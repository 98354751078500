import React from 'react';
import { Container } from 'react-bootstrap'
import {HashRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header'
import Footer from './components/Footer'
import HomeScreen from './screens/HomeScreen';
import ExpirienceScreen from './screens/ExpirienceScreen'
import ProjectScreen from './screens/ProjectScreen';
import EducationScreen from './screens/EducationScreen';
import ServiceScreen from './screens/ServiceScreen';
import AboutScreen from './screens/AboutScreen';
import BlogScreen from './screens/BlogScreen';
import BlogDetailScreen from './screens/BlogDetailScreen';
import ContactScreen from './screens/ContactScreen';
import LoginScreen from './screens/LoginScreen'
import RegisterScreen from './screens/RegisterScreen'
import ContactListScreen from './screens/ContactListScreen';
import BlogEditScreen from './screens/BlogEditScreen';
import BlogListScreen from './screens/BlogListScreen';
import UserListScreen from './screens/UserListScreen'
import CourseScreen from './screens/CourseScreen';
import ForoDetailScreen from './screens/ForoDetailScreen';
import ForoScreen from './screens/ForoScreen';
import ForoEditScreen from './screens/ForoEditScreen';
import ForoListScreen from './screens/ForoListScreen';
import ProfileScreen from './screens/ProfileScreen';
import SoftIntegralScreen from './screens/SoftIntegralScreen';

function App() {

  return (
    <Router>
      <Header />
      <main className='py-5'>
        <Container>
        <Routes>
          <Route path='/' element={<HomeScreen />} exact />
          <Route path='/login' element={<LoginScreen />}/>
          <Route path='/register' element={<RegisterScreen />}/>          
          <Route path='/exp' element={<ExpirienceScreen />}/>
          <Route path='/project' element={<ProjectScreen />}/>
          <Route path='/education' element={<EducationScreen />}/>
          <Route path='/service' element={<ServiceScreen />}/>
          <Route path='/about' element={<AboutScreen />}/>
          <Route path='/course' element={<CourseScreen />}/>
          <Route path='/sintegral' element={<SoftIntegralScreen />}/>                                        
          <Route path='/blog' element={<BlogScreen />}/>
          <Route path='/article/:id' element={<BlogDetailScreen />} />
          <Route path='/contact' element={<ContactScreen />}/>
          <Route path='/foro' element={<ForoScreen />} />
          <Route path='/foros/:id' element={<ForoDetailScreen />} />
          <Route path='/profileuser' element={<ProfileScreen />}/>
          <Route path='/admin/forolist' element={<ForoListScreen />}/>
          <Route path='/admin/foro/:id/edit' element={<ForoEditScreen />}/>          
          <Route path='/admin/contactlist' element={<ContactListScreen />}/>
          <Route path='/admin/articlelist' element={<BlogListScreen />}/>
          <Route path='/admin/article/:id/edit' element={<BlogEditScreen />}/>
          <Route path='/admin/userlist' element={<UserListScreen />}/>                                                 
        </Routes>
        </Container>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
