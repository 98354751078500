import { 
    BADGE_LIST_REQUEST, 
    BADGE_LIST_SUCCESS, 
    BADGE_LIST_FAIL,
    BADGE_DETAILS_REQUEST,
    BADGE_DETAILS_SUCCESS,
    BADGE_DETAILS_FAIL,
    BADGE_DELETE_REQUEST, 
    BADGE_DELETE_SUCCESS, 
    BADGE_DELETE_FAIL,

    BADGE_CREATE_REQUEST, 
    BADGE_CREATE_SUCCESS, 
    BADGE_CREATE_FAIL,
    BADGE_CREATE_RESET,
    
    BADGE_UPDATE_REQUEST, 
    BADGE_UPDATE_SUCCESS, 
    BADGE_UPDATE_FAIL,
    BADGE_UPDATE_RESET,

} from '../constants/badgeConstants'

export const badgeListReducer = (state = {badges: []}, action) => {
    switch(action.type){
        case BADGE_LIST_REQUEST:
            return {loading: true, badges:[]}
        case BADGE_LIST_SUCCESS:
            return {loading: false, badges: action.payload}
        case BADGE_LIST_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state    
    }
}

export const badgeDetailsReducer = (state = {badge: {reviews:[]}}, action) => {
    switch(action.type){
        case BADGE_DETAILS_REQUEST:
            return { loading: true, ...state }
        case BADGE_DETAILS_SUCCESS:
            return {loading: false, badge: action.payload}
        case BADGE_DETAILS_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state    
    }
}

export const badgeCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case BADGE_CREATE_REQUEST:
            return { loading: true }
    
        case BADGE_CREATE_SUCCESS:
            return { loading: false, success: true, badge: action.payload }
    
        case BADGE_CREATE_FAIL:
            return { loading: false, error: action.payload }
    
        case BADGE_CREATE_RESET:
                return {}            
    
        default:
            return state
    }
    }
    
    
    export const badgeDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case BADGE_DELETE_REQUEST:
            return { loading: true }
    
        case BADGE_DELETE_SUCCESS:
            return { loading: false, success: true }
    
        case BADGE_DELETE_FAIL:
            return { loading: false, error: action.payload }
    
        default:
            return state
    }
    }
    
    
    export const badgeUpdateReducer = (state = {badge: {}}, action) => {
    switch (action.type) {
        case BADGE_UPDATE_REQUEST:
            return { loading: true }
    
        case BADGE_UPDATE_SUCCESS:
            return { loading: false, success: true, badge: action.payload }
    
        case BADGE_UPDATE_FAIL:
            return { loading: false, error: action.payload }
    
        case BADGE_UPDATE_RESET:
                return { badge: {} }            
    
        default:
            return state
        }
    }