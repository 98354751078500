import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Button, Card, Form } from 'react-bootstrap';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { listExpiriences } from '../actions/expirienceActions'
import { listMentors } from '../actions/mentorActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import image1 from '../assets/images/experience-page/experience.svg'
import Expirience from '../components/Expierience'
import Mentor from '../components/Mentor';

function ExpirienceScreen() {

  const dispatch = useDispatch()

  let navigate = useNavigate();
  let keyword = useLocation().search

  const expirienceList = useSelector(state => state.expirienceList)
  const { error, loading, expiriences } = expirienceList

  const mentorList = useSelector(state => state.mentorList)
  const { loading: loadingList, error: errorList, mentors } = mentorList

  useEffect(() => {
    dispatch(listExpiriences(keyword))
    dispatch(listMentors(keyword))

     window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
     
  }, [dispatch, keyword])
  
  return (

    <div>
    <Link to='/' className='btn btn-light mt-2'>Go Back</Link>
    <section >
      <div class="container-fluid px-3 pt-5">
        <div class="row text-dark">
          <div class="col-md-6 my-2"> <img class="img-fluid d-block  animate__animated animate__fadeInUp animate__delay-1s"
              src={image1} />
          </div>
          <div
            class="px-md-5 p-3 col-md-6 d-flex flex-column align-items-center justify-content-center animate__animated animate__fadeInUp animate__delay-1s">
            <h1 class="main-heading mt-3 text-info" >Experiencia</h1>
            <p class="h5  mt-2 pre-heading font-weight-bolder text-center animate__animated animate__fadeInUp animate__delay-2s">
              La experiencia no es lo que te sucede, sino lo que haces con lo que te sucede. <br /> <br /> <span className='text-info fs-6 mt-1'>- Aldous Huxley.</span>              
            </p>
          </div>
        </div>
      </div>
    </section>

    <h2 class="text-center text-info exp-head heading1 my-5 animate__animated animate__zoomIn animate__delay-1s formal-edu">Experiencia Laboral</h2>

    {
      loading ? <Loader /> 
      : error ? <Message variant='danger'>{error}</Message>
        : <div>
        <Row className='mx-1'>
          {expiriences.length === 0 ? <h4><b>No existen Experiencias actualmente</b></h4> : ''}
            {expiriences.map(expirience => (
              <Col key={expirience._id} sm={12}>
                  <Expirience expirience={expirience} />
              </Col>                        
          ))}
        </Row>
        </div>
  }


    <h2 class="text-center heading1 my-5">Mentorship</h2>

    {
      loadingList ? <Loader /> 
      : errorList ? <Message variant='danger'>{errorList}</Message>
        : <div>
        <Row className='mx-1'>
          {mentors.length === 0 ? <h4><b>No existen Mentores actualmente</b></h4> : ''}
            {mentors.map(mentor => (
              <Col key={mentor._id} sm={12}>
                  <Mentor mentor={mentor} />
              </Col>                        
          ))}
        </Row>
        </div>
  }

  </div>


  )
}

export default ExpirienceScreen
