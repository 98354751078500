import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { expirienceListReducer, expirienceDetailsReducer, expirienceCreateReducer, expirienceDeleteReducer, expirienceUpdateReducer } from './reducers/expirienceReducers';
import { mentorListReducer, mentorDetailsReducer, mentorCreateReducer, mentorDeleteReducer, mentorUpdateReducer } from './reducers/mentorReducers';
import { projectListReducer, projectDetailsReducer, projectCreateReducer, projectDeleteReducer, projectUpdateReducer } from './reducers/projectReducers';
import { educationListReducer, educationDetailsReducer, educationCreateReducer, educationDeleteReducer, educationUpdateReducer } from './reducers/educationReducers';
import { moocListReducer, moocDetailsReducer, moocCreateReducer, moocDeleteReducer, moocUpdateReducer } from './reducers/moocReducers';
import { badgeListReducer, badgeDetailsReducer, badgeCreateReducer, badgeDeleteReducer, badgeUpdateReducer } from './reducers/badgeReducers';
import { serviceListReducer, serviceDetailsReducer, serviceCreateReducer, serviceDeleteReducer, serviceUpdateReducer } from './reducers/serviceReducers';
import { articleListReducers, articleDetailsReducers, articleDeleteReducer, articleCreateReducer, articleUpdateReducer } from './reducers/articleReducers'
import { contactListReducer, contactDetailsReducer, contactCreateReducer, contactDeleteReducer, contactUpdateReducer } from './reducers/contactReducers';
import { userLoginReducer, userRegisterReducer, userDetailsReducer, userUpdateProfileReducer, userListReducer, userDeleteReducer, userUpdateReducer } from './reducers/userReducers'
import { foroListReducers, foroDetailsReducers, foroDetailCreateReducer, foroCreateReducer, foroDeleteReducer, foroUpdateReducer } from './reducers/foroReducers';
import { profileListReducer, profileDetailsReducer, profileCreateReducer, profileDeleteReducer, profileUpdateReducer, profileListMyReducer } from './reducers/profileReducers';


const reducer = combineReducers({
    expirienceList: expirienceListReducer,
    expirienceDetails: expirienceDetailsReducer,
    expirienceDelete: expirienceDeleteReducer,
    expirienceCreate: expirienceCreateReducer,
    expirienceUpdate: expirienceUpdateReducer,
    mentorList: mentorListReducer,
    mentorDetails: mentorDetailsReducer,
    mentorDelete: mentorDeleteReducer,
    mentorCreate: mentorCreateReducer,
    mentorUpdate: mentorUpdateReducer,
    projectList: projectListReducer,
    projectDetails: projectDetailsReducer,
    projectDelete: projectDeleteReducer,
    projectCreate: projectCreateReducer,
    projectUpdate: projectUpdateReducer,
    educationList: educationListReducer,
    educationDetails: educationDetailsReducer,
    educationDelete: educationDeleteReducer,
    educationCreate: educationCreateReducer,
    educationUpdate: educationUpdateReducer,
    moocList: moocListReducer,
    moocDetails: moocDetailsReducer,
    moocDelete: moocDeleteReducer,
    moocCreate: moocCreateReducer,
    moocUpdate: moocUpdateReducer,
    badgeList: badgeListReducer,
    badgeDetails: badgeDetailsReducer,
    badgeDelete: badgeDeleteReducer,
    badgeCreate: badgeCreateReducer,
    badgeUpdate: badgeUpdateReducer,
    serviceList: serviceListReducer,
    serviceDetails: serviceDetailsReducer,
    serviceDelete: serviceDeleteReducer,
    serviceCreate: serviceCreateReducer,
    serviceUpdate: serviceUpdateReducer,
    articleList: articleListReducers,
    articleDetails: articleDetailsReducers,
    articleDelete: articleDeleteReducer,
    articleCreate: articleCreateReducer,
    articleUpdate: articleUpdateReducer,
    contactList: contactListReducer,
    contactDetails: contactDetailsReducer,
    contactDelete: contactDeleteReducer,
    contactCreate: contactCreateReducer,
    contactUpdate: contactUpdateReducer,
    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,
    userDetails: userDetailsReducer,
    userUpdateProfile: userUpdateProfileReducer,
    userList: userListReducer,
    userDelete: userDeleteReducer,
    userUpdate: userUpdateReducer,
    foroList: foroListReducers,
    foroDetails: foroDetailsReducers,
    foroDetailCreate: foroDetailCreateReducer,
    foroDelete: foroDeleteReducer,
    foroCreate: foroCreateReducer,
    foroUpdate: foroUpdateReducer,
    profileList: profileListReducer,    
    profileDetails: profileDetailsReducer,
    profileDelete: profileDeleteReducer,
    profileCreate: profileCreateReducer,
    profileUpdate: profileUpdateReducer,
    profileListMy: profileListMyReducer,        
})

const userInfoFromStorage = localStorage.getItem('userInfo') ?
        JSON.parse(localStorage.getItem('userInfo')) : null

const initialState = {
    userLogin: { userInfo: userInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store