import React, { useEffect, useState } from 'react';
import { Row, Col, Image, Card } from 'react-bootstrap';
import Moment from 'react-moment';
import { Link } from 'react-router-dom'
import ScrollAnimation from 'react-animate-on-scroll';

function Education({ education }) {

  useEffect(() => {

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    
}, [])

  return (
    <div class="mt-2">
    <ScrollAnimation animateIn="fadeIn duration='3s' offset">
    <div class="card animate__animated animate__fadeInUp animate__delay-1s border border-info border-3 rounded">
    <div class="timeline-content">
      <p class="text-center text-bold pt-1 fs-2 text-info"><b>{education.name}</b></p>
      <div class="row">
        <div class="col-md-3">
          <img src={education.image} alt="" height="110"
            class="mt-2 w-100 ms-auto" />
        </div>
        <div class="col-md-9 ">
          <span style={{opacity: 0.9, fontSize: 17}} className='fw-bold'>
            <b>{education.heading}</b>
          </span>
          <p className='fw-bold'>Período:{education.timeline}</p>
          <ol style={{marginTop: -8, marginLeft: -10, fontSize: 15}}>
            {education.comment}
          </ol>
        </div>
      </div>
    </div>

  </div>
  </ScrollAnimation>

    </div>
  )
}

export default Education